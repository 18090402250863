import React from 'react'
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Menu, MenuItem } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import sidebarConfig from './SidebarConfig'
import ConnectBC from './connect-bc';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    // minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  },
  borderBottom: '1px solid #E5E8EB'
}));

// ----------------------------------------------------------------------

const apps = [
  {code: 'app1', label: 'App 1'},
  {code: 'app3', label: 'App 3'},
  {code: 'settings', label: 'Settings'},
]

const DashboardNavbar = ({ onOpenSidebar }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeApp = (app) => {
    const current_app = localStorage.getItem('app');
    if (app !== current_app) {
      localStorage.setItem('app', app);
      const first_route = sidebarConfig.find(e => e.apps.includes(app) || !e.apps)
      if (first_route) {
        window.location.href = first_route.path
      }
    }
    handleClose()
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Button
          id="button-app"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ fontSize: 16, marginRight: 10 }}
          startIcon={<AppsIcon />}
        >
          {apps.find(e => e.code == localStorage.getItem('app'))?.label}
        </Button>
        <Menu
          id="menu-app"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'button-app',
          }}
        >
          {apps.map(app => (
            <MenuItem key={app.code} onClick={() => handleChangeApp(app.code)}>{app.label}</MenuItem>
          ))}
        </Menu>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <ConnectBC />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default DashboardNavbar;