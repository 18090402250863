import * as t from '../_constants_/redux';
import { request } from '../_services_';
import toastr from 'toastr';

export const getListRequest = (query) => async (dispatch) => {
  request
    .get('/admin/v1/request_ad_account_tiktoks', query)
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.LIST_REQUEST,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

export const updateRequestAd = (id, data) => async (dispatch) => {
  dispatch({
    type: t.UPDATE_REQUEST_AD,
    payload: null,
    updateRequestLoading: true,
  });
  request
    .put(`/admin/v1/request_ad_account_tiktok/${id}`, data)
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.UPDATE_REQUEST_AD,
          payload: data,
          updateRequestLoading: false,
        });
        toastr.success('Update ad account successful!');
      } else {
        dispatch({
          type: t.UPDATE_REQUEST_AD,
          payload: null,
          updateRequestLoading: false,
        });
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

export const getDataRequestUpdate = () => async (dispatch) => {
  request
    .get('/api/v1/request_ad_account_tiktok')
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.DATA_REQUEST_UPDATE,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

export const getBC = () => async (dispatch) => {
  try {
    const res = await request.get('/admin/v1/business_centers');

    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.GET_BC,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};
