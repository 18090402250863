import * as t from '../_constants_/redux';

const initialState = {
  listRequests: null,
  dataRequest: null,
  dataRequestUpdate: null,
  dataUpdateRequest: null,
  dataBC: null,
  updateRequestLoading: false,
};

export function requestTiktok(state = initialState, action) {
  switch (action.type) {
    case t.LIST_REQUEST: {
      return {
        ...state,
        listRequests: action.payload
      };
    }
    case t.REQUEST_TIKTOK_ACC: {
      return {
        ...state,
        dataRequest: [action.payload]
      };
    }
    case t.UPDATE_REQUEST_AD: {
      return {
        ...state,
        dataUpdateRequest: action.payload,
        updateRequestLoading: action?.updateRequestLoading,
      };
    }
    case t.DATA_REQUEST_UPDATE: {
      return {
        ...state,
        dataRequestUpdate: action.payload
      };
    }
    case t.GET_BC: {
      return {
        ...state,
        dataBC: action.payload
      };
    }
    default:
      return state;
  }
}
