import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { ModalForm, ResourceList, DrawerForm } from '../../../components';
import { fields, formFields, filters } from './constants';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import { checkVisible, checkRequired, schemaArray } from '../../../utils';
import ServicePopup from './ServicePopup';
import validate from 'validate.js'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

const checkServiceAA1 = (submitData) => {
  let wallet = Number.parseFloat(submitData.values?.wallet_a)
  if (submitData.values.product_id?.code == 'A') {
    wallet += Number.parseFloat(submitData.values?.fee)
  }
  let topup = 0
  submitData.values.accounts?.map((acc) => {
    topup += Number.parseFloat(acc.money || 0)
  })
  if (wallet < topup && submitData.values.product_id?.code == 'A1') {
    return {msg: 'Money topup to account must be equal or less than wallet remaining'}
  }
  return false
}

class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  createService = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "Buy Service",
      data: {
        extra_field: []
      },
      customView: (submitData, handleChange, ctxModal) => (
        <ServicePopup 
          submitData={submitData}
          handleChange={handleChange}
          user={this.props.user}
          ctx={ctxModal}
        />
      ),
      multipleActions: true,
      actions: [
        {
          titleAction: 'Save',
          visible: (submitData, ctxModal) => !ctxModal.state.openForm,
          schema: (submitData) => {
            var schema = {
              team_id: {
                presence: {allowEmpty: false, message: '^Required'}
              },
              product_id: {
                presence: {allowEmpty: false, message: '^Required'}
              }
            }
            if (submitData.values.product_id?.code == 'A' && submitData.values.fee > 0) {
              schema['fud_id'] = {
                presence: {allowEmpty: false, message: '^Required'}
              }
            } else if (['C', 'D'].includes(submitData.values.product_id?.code)) {
              schema['num_account'] = {
                presence: {allowEmpty: false, message: '^Required'},
                numericality: {
                  greaterThan: 0,
                  message: '^Must be greater than 0'
                }
              }
            } else if (submitData.values.product_id?.code == 'B') {
              schema['package_id'] = {
                presence: {allowEmpty: false, message: '^Required'},
              }
              if (submitData.values.option == 'upgrade_fud'){
                schema['fud_id'] = {
                  presence: {allowEmpty: false, message: '^Required'},
                }
              }
            } else {
              const keys = submitData.values.product_id?.extra_field?.filter(field => checkVisible(field, submitData.values)).filter(field => checkRequired(field, submitData.values)).map(field => field.key) || []
              keys.map(key => {
                schema[key] = {
                  presence: {allowEmpty: false, message: '^Required'}
                }
              })
            }
            if (submitData.values.product_id?.dynamic_fee) {
              schema['fee'] = {
                presence: {allowEmpty: false, message: '^Required'},
                numericality: {
                  greaterThan: 0,
                  message: '^Must be greater than 0'
                }
              }
            }
            return schema
          },
          onAction: (submitData, ctxModal) => {
            return new Promise((resolve) => {
              const allKeys = Object.keys(submitData.values)
              var query = {}
              if (['A', 'A1'].includes(submitData.values.product_id?.code)) {
                const fund_remaining = submitData.values.fud_id?.remaining_topup
                if (Number.parseFloat(submitData.values.fee) > 0 && fund_remaining < Number.parseFloat(submitData.values.fee)) {
                  toastr.error("Fund is not enough")
                  resolve(false)
                  return
                }
                if (submitData.values.product_id?.code == 'A1') {
                  if (!submitData.values.accounts) {
                    toastr.error("Accounts required")
                    resolve(false)
                    return
                  }
                  const inValid = checkServiceAA1(submitData)
                  if (inValid) {
                    toastr.error(inValid.msg)
                    resolve(false)
                    return
                  }
                }
                const keys = allKeys.filter(e => e != 'extra_field')
                keys.map(key => {
                  if (key == 'team_id') {
                    query[key] = submitData.values[key].team_id
                  } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                    query[key] = submitData.values[key]?.id || 0
                  } else {
                    query[key] = submitData.values[key]
                  }
                })
                if (submitData.values.product_id?.code == 'A1') {
                  query['extra_field'] = submitData.values.extra_field
                }
              } else if (submitData.values.product_id?.code == 'B') {
                if (submitData.values.option == 'upgrade_fud') {
                  const old_package_money = submitData.values.fud_id?.package_money || 0
                  const new_package_money = submitData.values.package_id?.total_topup_30days || 0
                  if (old_package_money >= new_package_money) {
                    toastr.error("Level is invalid")
                    resolve(false)
                    return
                  }
                }
                const keys = allKeys.filter(e => e != 'extra_field')
                keys.map(key => {
                  if (key == 'team_id') {
                    query[key] = submitData.values[key].team_id
                  } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                    query[key] = submitData.values[key]?.id || 0
                  } else {
                    query[key] = submitData.values[key]
                  }
                })
              } else if (['C', 'D'].includes(submitData.values.product_id?.code)) {
                const keys = allKeys.filter(e => e != 'extra_field')
                keys.map(key => {
                  if (key == 'team_id') {
                    query[key] = submitData.values[key].team_id
                  } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                    query[key] = submitData.values[key]?.id || 0
                  } else {
                    query[key] = submitData.values[key]
                  }
                })
              } else {
                allKeys.map(key => {
                  if (key == 'team_id') {
                    query[key] = submitData.values[key].team_id
                  } else if (key == 'extra_field') {
                    query[key] = submitData.values[key]
                  } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                    query[key] = submitData.values[key]?.id || 0
                  } else if(key == 'discount_fee') {
                    return
                  } else {
                    query[key] = submitData.values[key]
                  }
                })
              }
              if (Object.keys(query).includes('extra_field')) {
                const field_array = query['extra_field'].find(e => e.type == 'array')
                if (field_array) {
                  const schema = schemaArray(field_array)
                  let num_errors = 0
                  field_array.value.map(f_value => {
                    const errors = validate(f_value, schema)
                    f_value['_errors'] = errors
                    if (errors) {
                      num_errors += 1
                    }
                  })
                  if (num_errors > 0) {
                    resolve(false)
                    return
                  }
                }
              }
              request.post('/api/v1/services', query).then(res => {
                if (res.data.success) {
                  toastr.success(`Buy service successfully ${submitData.values.product_id?.code == 'B' ? 'with FUD ' + res.data.data.fud_id : ''}`)
                  if (submitData.values.product_id?.code == 'A' && submitData.values.topup_to_account == 1) {
                    resolve(false)
                    ctxModal.setState({ openForm: true })
                    const data = Object.assign({}, submitData)
                    data.values['product_id'] = res.data.data.product_id
                    data.values['wallet_a'] = res.data.data.wallet_a
                    data.values['note'] = ""
                    data.values['fee'] = res.data.data.product_id.price
                    delete data.values['fud_id']
                    delete data.values['topup_to_account']
                    ctxModal.setState({ submitData: data })
                    return
                  } else {
                    resolve(true)
                    ctx.getListItems(ctx.state.searchQuery)
                  }
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                resolve(false)
                toastr.error(err)
              })
            })
          }
        },
        {
          titleAction: 'Save', // Save A1
          visible: (submitData, ctxModal) => ctxModal.state.openForm,
          schema: (submitData) => {
            var schema = {
              product_id: {
                presence: {allowEmpty: false, message: '^Required'}
              },
              team_id: {
                presence: {allowEmpty: false, message: '^Required'}
              },
            }
            const keys = submitData.values.product_id?.extra_field?.filter(field => checkVisible(field, submitData.values)).filter(field => checkRequired(field, submitData.values)).map(field => field.key) || []
            keys.map(key => {
              schema[key] = {
                presence: {allowEmpty: false, message: '^Required'}
              }
            })
            return schema
          },
          onAction: (submitData, ctxModal) => {
            return new Promise((resolve) => {
              const allKeys = Object.keys(submitData.values)
              var query = {}
              const inValid = checkServiceAA1(submitData)
              if (inValid) {
                toastr.error(inValid.msg)
                resolve(false)
                return
              }
              const keys = allKeys.filter(e => e != 'extra_field')
              keys.map(key => {
                if (key == 'team_id') {
                  query[key] = submitData.values[key].team_id
                } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                  query[key] = submitData.values[key]?.id || 0
                } else {
                  query[key] = submitData.values[key]
                }
              })
              query['extra_field'] = submitData.values.extra_field
              if (Object.keys(query).includes('extra_field')) {
                const field_array = query['extra_field'].find(e => e.type == 'array')
                if (field_array) {
                  const schema = schemaArray(field_array)
                  let num_errors = 0
                  field_array.value.map(f_value => {
                    const errors = validate(f_value, schema)
                    f_value['_errors'] = errors
                    if (errors) {
                      num_errors += 1
                    }
                  })
                  if (num_errors > 0) {
                    resolve(false)
                    return
                  }
                }
              }
              request.post('/api/v1/services', query).then(res => {
                if (res.data.success) {
                  toastr.success(`Buy service successfully ${submitData.values.product_id?.code == 'B' ? 'with FUD ' + res.data.data.fud_id : ''}`)
                  resolve(true)
                  ctx.getListItems(ctx.state.searchQuery)
                  ctxModal.setState({ openForm: false })
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                resolve(false)
                toastr.error(err)
              })
            })
          }
        }
      ]
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    
    request.put(`/api/v1/service/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  cancelServices = (ctx, items) => {
    ModalForm.instance.current.openForm({
      title: "Cancel Service?",
      data: {},
      customView: () => (
        <div className='p-2'>Are you sure to cancel {items.length} service(s)?</div>
      ),
      action: {
        titleAction: 'Confirm',
        onAction: () => {
          return new Promise((resolve) => {
            const query = {
              action: 'cancelled',
              ids: items
            }
            request.put('/api/v1/services', query).then(res => {
              if (res.data.success) {
                const items = ctx.state.items.map(e => res.data.data.includes(e.id) ? {...e, cancelled: true} : e)
                ctx.setState({items, selectedItems: []})
                toastr.success(`Cancelled ${res.data.data.length} service(s)`)
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  render () {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          route="/admin/v1/services"
          titlePage="SpendAds | Services"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.createService(ctx),
              visible: true
            }
          ]}
          title="Service ID"
          fields={(ctx) => fields(this, ctx)}
          color={(item) => item.cancelled ? 'red' : 'black'}
          filters={filters}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          headerActions={[
            {
              text: 'Cancel',
              variant: 'outlined',
              icon: <DoNotDisturbIcon />,
              action: (ctx, cancelServices) => this.cancelServices(ctx, cancelServices),
              color: 'error'
            }
          ]}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/service/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Service Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ServiceList)