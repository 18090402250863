import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList } from '../../../components';
import { fields, formfields, filters } from './constants';
import { request } from '../../../_services_';
import { DrawerForm } from 'src/components/DrawerForm';
import Toastr from 'src/_helpers_/toastr';
import ModalForm from 'src/components/ModalForm';
import { Box } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, disabledStyles } from '../../../utils/selectStyle';

class BankTransactionList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (id, submitData) => {
    const query = {}
    const allKeys = Object.keys(submitData.valueChanges)
    allKeys.map(key => {
      if (submitData.valueChanges[key] instanceof Object || typeof submitData.valueChanges[key] == 'object') {
        query[key] = submitData.valueChanges[key].id
      } else if (typeof submitData.valueChanges[key] == 'boolean') {
        query[key] = submitData.valueChanges[key] ? 1 : 0
      } else {
        query[key] = submitData.valueChanges[key]
      }
    })
    request.put(`/admin/v1/bank_transaction/${id}`, query).then(res => {
      if (res.data.success) {
        Toastr.success("Topup to team successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        Toastr.error(res.data.msg)
      }
    }, err => {
      Toastr.error(err.response.data.msg)
    })
  }

  handleTopup = (item) => {
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Team topup',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Team
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => {
                return new Promise(resolve => {
                  request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.team_id, name: `Team ${e.team_id}`})))
                    } else {
                      resolve([])
                    }
                  }, error => {
                    resolve([])
                  })
                })
              }}
              defaultOptions
              onInputChange={(newValue) => newValue}
              isSearchable
              name={"team_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "team_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Team"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData.values.team_id ? {id: submitData.values.team_id.id, name: `Team ${submitData.values.team_id.id}`} : ''}
              styles={submitData.errors?.team_id ? errorStyles : customStyles}
            />
            {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id[0]}</span>}
          </div>
        </div>
      ),
      action: {
        schema: {
          team_id: { presence: { allowEmpty: false, message: '^Required' } }
        },
        titleAction: 'Confirm',
        onAction: (submitData) => {
          return new Promise((resolve) => {
            this.handleSave(item.id, submitData)
            resolve(true)
          })
        }
      }
    })
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/bank_transactions"
          titlePage="SpendAds | Bank transaction"
          title="Bank Transaction"
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          // defaultFilters={{}}
          actionRow={{
            title: 'Topup',
            action: (item) => this.handleTopup(item),
            visibled: (item) => item.status != 'success'
          }}
          filters={filters}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/bank_transaction/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Transaction Detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(BankTransactionList)