import React, { Component } from 'react'
import { listItems } from './fields';
import { ResourceList } from '../../../components';

class CreatingAccList extends Component {

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList 
        titlePage="SpendAds | Creating Acc"
        title="Creating Acc"
        valueKey="team_id"
        type={'static'}
        data={listItems}
        navigate = {navigate}
      />
    )
  }

}

export default CreatingAccList;