import React, { Component } from 'react'
import { listRacIdNewFields } from './fields';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class RacIDNewList extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/api/v1/newids"
        titlePage="SpendAds | 7.1 RAC Acc ID new"
        title="7.1 RAC Acc ID new"
        fields={listRacIdNewFields}
        defaultFilters={{list:'id_new', type_acc: 'RAC'}}
        valueKey="account_id"
        onClickRow={(item) => navigate(`/app3/rac_id_new/${item.public_id}`)}
        actions={[
        ]}
      />
    )
  }

}

export default RacIDNewList;