import { Typography, FormHelperText, FormControl, Box } from '@mui/material';
import React from 'react';
import Select from 'react-select';
import { errorStyles } from 'src/utils';

export default function SelectSearch(props) {
  const {
    title,
    classNameSpecificUI,
    value,
    onChange,
    placeholder,
    options,
    error,
    disabled = false,
    required = false
  } = props;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      boxShadow: 'unset',
      '&:hover': {
        borderColor: 'green',
        boxShadow: 'green'
      }
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      height: '47px',
      fontSize: '14px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': { width: '8px' }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 99999
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: 'normal',
      lineHeight: '1'
    })
  };

  const formatOptionLabel = ({ label }) => (
    <span dangerouslySetInnerHTML={{ __html: label }} />
  );

  return (
    <FormControl className={classNameSpecificUI}>
      {title && (
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography fontWeight={500}>{title}</Typography>
          {required && <Typography sx={{ color: 'red' }}>*</Typography>}
        </Box>
      )}
      <Select
        options={options}
        value={options.find((option) => option.value === value) ?? ''}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        variant="outlined"
        styles={error ? errorStyles : customStyles}
        formatOptionLabel={formatOptionLabel}
      />
      <FormHelperText className='field-error'>{!!error && error}</FormHelperText>
    </FormControl>
  );
}
