import { Box, MenuItem, Select, Button, Typography, Link } from '@mui/material';
import { COLOR_BLACK, COLOR_WHITE } from '../../_constants_';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBC } from '../../_actions_/request-tiktok.actions';
import { formatDataOptionBC } from '../../_helpers_';

export default function ConnectBC() {
  const dataBC = useSelector((state) => state?.requestTiktok?.dataBC);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authentication?.user);
  const redirectUri = process.env.REACT_APP_TIKTOK_REDIRECT_URI;
  const url = `${process.env.REACT_APP_TIKTOK_APP}&state=${user?.user_id}&redirect_uri=${redirectUri}`;

  useEffect(() => {
    if (!dataBC) {
      dispatch(getBC());
    }
  }, []);

  return (
    <Box sx={{ color: COLOR_BLACK }}>
      <Box display="flex" gap={2}>
        {dataBC && (
          <Select
            value={dataBC[0]?.value}
            onChange={() => {}}
            placeholder={'placeholder'}
            variant="outlined"
            sx={{
              minWidth: '220px',
              maxHeight: '50px'
            }}
          >
            {dataBC &&
              dataBC.length > 0 &&
              formatDataOptionBC(dataBC).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography
                    component="span"
                    sx={{
                      whiteSpace: 'pre-line',
                      fontSize: 13
                    }}
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        )}
        <Button
          variant="contained"
          sx={{
            textTransform: 'none',
            p: 0
          }}
        >
          <Link
            href={url}
            sx={{
              color: COLOR_WHITE,
              textDecoration: 'none',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              p: '6px 16px'
            }}
          >
            Connect to TikTok
          </Link>
        </Button>
      </Box>
    </Box>
  );
}
