import TypeAcc from "./TypeAcc"
import { Button } from '@mui/material';
import React from 'react';
import { getButtonColor } from '../../../_helpers_';
import { COLOR_WHITE, modeScreen, settingOptions } from '../../../_constants_';
export const listFields = [
  {
    name: 'code',
    label: 'Code',
    sort: true,
  },
  {
    name: 'title',
    label: 'Platform name',
    sort: true
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    sort: true,
    renderValue: (item) => item.type_acc?.length > 0 ? item.type_acc.map(e => e.name).join(', ') : ''
  },
  {
    name: 'status',
    label: 'Status',
    sort: false,
    renderValue: (item) => {
      return (<Button
        variant='contained'
        size='small'
        sx={{
          cursor: 'unset !important',
          borderRadius: '12px',
          fontSize: 14,
          color: COLOR_WHITE,
          borderColor: COLOR_WHITE,
          width: '150px',
          textTransform: 'none',
          backgroundColor: `${getButtonColor(item.status, settingOptions)}!important`
        }}
      >
        {item.status ?? '--'}
      </Button>)
    }
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'code',
    label: 'Code',
  },
  {
    type: 'text',
    name: 'title',
    label: 'Platform name',
  },
  {
    type: 'selectOptions',
    name: 'status',
    label: 'Status',
    options: settingOptions,
    placeholder: 'Select status',
    renderValue: (item) => item.status ? {value: item.status, label: item.status} : null,
  },
  {
    type: 'custom',
    name: 'Type Acc',
    label: 'Type Acc',
    renderValue: (item, ctx) => <TypeAcc disabled={ctx.props.mode === modeScreen.view} item={item} ctx={ctx} />
  }
]
