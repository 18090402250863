import React from 'react';
import { Button, Box } from '@mui/material';
import InputField from '../../elements/input';
import { views } from '../../../_constants_';
import TextareaField from '../../elements/textarea';
import { formatDateToTimezone } from '../../../_helpers_';

export const ShowRefund = ({ refund, changeView }) => {
  return (
    <Box display={'flex'} flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
        <Button
          variant="contained"
          onClick={() => {
            changeView(views.edit);
          }}
        >
          Edit
        </Button>
      </Box>
      {refund && (
        <Box display="flex" flexDirection="column" gap={2.5}>
          <InputField disabled title="Request ID" value={`Request ${refund?.id}`} />

          <InputField disabled title="Timestamp" value={formatDateToTimezone(refund?.create_date)} />

          <InputField disabled title="Requested Amount" value={`$ ${refund?.amount}`} />

          <InputField title="Refund reason" value={refund.refund_reason} disabled />

          <TextareaField title="Detail reason" value={refund.detail_reason} disabled />

          <InputField disabled title="Receiving method" value={refund?.receive_method} />

          <TextareaField disabled title="Recipient information" value={refund?.receiving_info} />

          <InputField disabled title="Team ID" value={`Team_${refund?.team_id}`} />

          <InputField disabled title="Status" value={refund?.status} />

          <InputField disabled title="Note" value={refund.note} />
        </Box>
      )}
    </Box>
  );
};
