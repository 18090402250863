import { Navigate, useRoutes, useNavigate } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import sidebarConfig from './layouts/dashboard/SidebarConfig';
//
import Login from './pages/Login';
import Register from './pages/Register';
import NotFound from './pages/Page404';
import { TeamForm as TeamActiveForm, TeamList as TeamActive } from './pages/App3/Teams';
import {
  RequestSupportList, RequestAll9b, RequestC1C2B3,
  RequestB5, RequestOther, RequestSupportForm, RaiAccDisable,
  RaiBMSharing, RacBMSharing, RequestB5Form, RaiBMSharingForm, RacBMSharingForm
} from './pages/App3/RequestSupport'
import { Acc2CList, Acc2CForm } from './pages/App3/2cAcc';
import { Request9bList } from './pages/App3/9bRequest';
import { RacAccList, RacAccForm } from './pages/App3/RacAcc';
import { SinIDList, SinIDForm } from './pages/App3/SinID';
import {
  CreatingAccList, SinCreateRefList, RefToReviewList,
  RefWebPageList, RefWebPageForm, RacNumberList, RacIDNewList, RacIDNewForm, RacNumberForm,
  RefToReviewForm, RacWarehouseList, RacWarehouseForm, RacAllList
} from './pages/App3/CreatingAcc';
import { 
  CmnNumberList, BrNumberList, EmailNumberList,
  CmnNumberForm
} from './pages/App3/Setting'
import { AdminUser } from './pages/Settings/AdminUser'
import navigationService from './_services_/navigation';

import Profile from './pages/App3/Profile';

// -------------------------
import { TeamForm, TeamList } from './pages/App1/Team';
import { TeamReportList, TeamReportForm } from './pages/App1/TeamReport';
import { TopupList, TopupForm } from './pages/App1/Topup';
import { FudList, FudForm } from './pages/App1/FUD';
import { AllUserList, AllUserForm } from './pages/App1/AllUser';
import { Acc2cApp1Form, Acc2cApp1List } from './pages/App1/2cAcc1';
import { Request9aList, Request9aForm } from './pages/App1/RequestSupport';
import {
  GSTopupList, RAITopupList, RACTopupList, RACTopupForm,
  GSTopupForm,
  RAITopupForm
} from './pages/App1/TopupAcc';
import { RaiAvailList, RaiAvailForm } from './pages/App1/RaiAvail';
import { FudCountList, FudCountForm } from './pages/App1/FudCount';
import { NeedFudForm, NeedFudList } from './pages/App1/NeedFUD';
import { ProductList } from './pages/Settings/Products'
import { ServiceList, ServiceForm } from './pages/Settings/Services'
import { RateExList } from './pages/App1/RateEx';
import { ManualNotificationList } from './pages/Settings/ManualNotification';
import CommissionList from './pages/App1/Commission/CommissionList';
import CommissionForm from './pages/App1/Commission/CommissionForm';
import { TemplateList } from './pages/Settings/Template';
import { TransactionHistoryList } from './pages/App1/TransactionHistory';
import { AccountPoolList } from './pages/App1/AccountPool';
import { AccountAllList } from './pages/App1/AccountAll';
import { BankTransactionList } from './pages/App1/BankTransaction';
import { PayoneerTransactionList } from './pages/App1/PayoneerTransactions';
import { TransferFundRemaining } from './pages/App1/TransferFundRemaining'
import { TeamPlatformList } from './pages/Settings/TeamPlatform';
import { DiscountList } from './pages/App1/Discount'
import RequestAdsAccount from './pages/App3/RequestAdsAccount';
import { APP3, SETTING } from './_constants_/router';
import RequestTiktokRefund from './pages/App3/RequestRefund';
import RequestProductLink from './pages/App3/ProductLink';

// ----------------------------------------------------------------------

export default function Router(props) {

  let navigate = useNavigate();
  navigationService.navigate = navigate;

  const getRedirectRoute = () => {
    const current_app = localStorage.getItem('app') || 'app1'
    const first_route = sidebarConfig.find(e => e.apps.includes(current_app) || !e.apps)
    if (first_route) {
      return first_route.path
    }
    return '/app3/team_management'/app1/team_reports
  }

  return useRoutes(
    (localStorage.getItem('user_admin_drebest') && JSON.parse(localStorage.getItem('user_admin_drebest')).token) ?
      [
        {
          path: '/',
          element: <DashboardLayout navigate={navigate} {...props} />,
          children: [
            {
              path: 'app3/team_active', element: <TeamActive navigate={navigate} onDetail={(item) => {
                navigate(`/app3/team_active/${item.team_id}`)
              }} />
            },
            {
              path: 'app3/team_active/:id', element: <TeamActiveForm navigate={navigate}
                onBack={() => {
                  navigate(`/app3/team_active`)
                }}
                route={'app3/team_active'}
              />
            },
            {
              path: 'app3/all_team', element: <TeamActive navigate={navigate} onDetail={(item) => {
                navigate(`/app3/all_team/${item.team_id}`)
              }} />
            },
            {
              path: 'app3/all_team/:id', element: <TeamActiveForm navigate={navigate}
                onBack={() => {
                  navigate(`/app3/all_team`)
                }}
                route={'app3/all_team'}
                type={1}
              />
            },
            { path: 'app3/request_support', element: <RequestSupportList navigate={navigate} /> },
            { path: 'app3/9b_request_all', element: <RequestAll9b navigate={navigate} /> },
            { path: 'app3/9b_request_c', element: <RequestC1C2B3 navigate={navigate} /> },
            { path: 'app3/9b_request_b5', element: <RequestB5 navigate={navigate} /> },
            { path: 'app3/9b_request_b5/:id', element: <RequestB5Form navigate={navigate} /> },
            { path: 'app3/9b_request_other', element: <RequestOther navigate={navigate} /> },
            { path: 'app3/request_support/:id', element: <RequestSupportForm navigate={navigate} /> },
            { path: 'app3/rai_acc_disable', element: <RaiAccDisable navigate={navigate} /> },
            { path: 'app3/rai_bm_sharing', element: <RaiBMSharing navigate={navigate} /> },
            { path: 'app3/rac_bm_sharing', element: <RacBMSharing navigate={navigate} /> },
            { path: 'app3/rai_bm_sharing/:id', element: <RaiBMSharingForm navigate={navigate} /> },
            { path: 'app3/rac_bm_sharing/:id', element: <RacBMSharingForm navigate={navigate} /> },

            { path: 'app3/2c_acc', element: <Acc2CList navigate={navigate} /> },
            { path: 'app3/2c_acc/:id', element: <Acc2CForm navigate={navigate} /> },
            { path: 'app3/9b_request', element: <Request9bList navigate={navigate} /> },
            { path: 'app3/sin_id', element: <SinIDList navigate={navigate} /> },
            { path: 'app3/sin_id/:id', element: <SinIDForm navigate={navigate} /> },

            { path: 'app3/rac_acc', element: <RacAccList navigate={navigate} /> },
            { path: 'app3/rac_acc/:id', element: <RacAccForm navigate={navigate} /> },

            { path: 'app3/creating_acc', element: <CreatingAccList navigate={navigate} /> },
            { path: 'app3/sin_ref', element: <SinCreateRefList navigate={navigate} /> },
            { path: 'app3/rac_numbers', element: <RacNumberList navigate={navigate} /> },
            { path: 'app3/rac_number/:id', element: <RacNumberForm navigate={navigate} /> },
            { path: 'app3/ref_reviews', element: <RefToReviewList navigate={navigate} /> },
            { path: 'app3/ref_review/:id', element: <RefToReviewForm navigate={navigate} /> },
            { path: 'app3/ref_web', element: <RefWebPageList navigate={navigate} /> },
            { path: 'app3/ref_web/:id', element: <RefWebPageForm navigate={navigate} /> },
            { path: 'app3/rac_id_new', element: <RacIDNewList navigate={navigate} /> },
            { path: 'app3/rac_id_new/:id', element: <RacIDNewForm navigate={navigate} /> },
            { path: 'app3/rac_warehouses', element: <RacWarehouseList navigate={navigate} /> },
            { path: 'app3/rac_warehouse/:id', element: <RacWarehouseForm navigate={navigate} /> },
            { path: 'app3/rac_all', element: <RacAllList navigate={navigate} /> },
            { path: 'app3/rac_topups', element: <RACTopupList navigate={navigate} /> },

            { path: 'app3/cmn_numbers', element: <CmnNumberList navigate={navigate} /> },
            { path: 'app3/br_numbers', element: <BrNumberList navigate={navigate} /> },
            { path: 'app3/email_numbers', element: <EmailNumberList navigate={navigate} /> },
            { path: APP3.REQUEST_REFUND, element: <RequestTiktokRefund navigate={navigate} /> },
            { path: APP3.REQUEST_AD_ACCOUNT, element: <RequestAdsAccount navigate={navigate} /> },
            { path: APP3.PRODUCT_LINK, element: <RequestProductLink navigate={navigate} /> },

            { path: 'app1/teams', element: <TeamList navigate={navigate} /> },
            { path: 'app1/team/:id', element: <TeamForm navigate={navigate} /> },
            { path: 'app1/team_reports', element: <TeamReportList navigate={navigate} /> },
            { path: 'app1/team_report/:id', element: <TeamReportForm navigate={navigate} /> },
            { path: 'app1/topups', element: <TopupList navigate={navigate} /> },
            { path: 'app1/topup/:id', element: <TopupForm navigate={navigate} /> },
            // { path: 'app1/fuds', element: <FudList navigate={navigate} /> },
            { path: 'app1/fud/:id', element: <FudForm navigate={navigate} /> },
            { path: 'app1/users', element: <AllUserList navigate={navigate} /> },
            { path: 'app1/user/:id', element: <AllUserForm navigate={navigate} /> },
            { path: 'app1/accounts', element: <Acc2cApp1List navigate={navigate} /> },
            { path: 'app1/accounts/:id', element: <Acc2cApp1Form navigate={navigate} /> },
            // { path: 'app1/request_supports', element: <Request9aList navigate={navigate} /> },
            { path: 'app1/request_9a1/:id', element: <Request9aForm navigate={navigate} /> },
            // { path: 'app1/rai_topups', element: <RAITopupList navigate={navigate} /> },
            // { path: 'app1/rai_topups/:id', element: <RAITopupForm navigate={navigate} /> },

            // { path: 'app1/rac_topups/:id', element: <RACTopupForm navigate={navigate} /> },
            { path: 'app1/gs_topups', element: <GSTopupList navigate={navigate} /> },
            { path: 'app1/gs_topups/:id', element: <GSTopupForm navigate={navigate} /> },

            { path: 'app1/rai_avail', element: <RaiAvailList navigate={navigate} /> },
            { path: 'app1/rai_avail/:id', element: <RaiAvailForm navigate={navigate} /> },
            { path: 'app1/fud_counts', element: <FudCountList navigate={navigate} /> },
            { path: 'app1/fud_count/:id', element: <FudCountForm navigate={navigate} /> },
            { path: 'app1/need_fuds', element: <NeedFudList navigate={navigate} /> },
            { path: 'app1/need_fud/:id', element: <NeedFudForm navigate={navigate} /> },

            
            { path: 'app1/services', element: <ServiceList navigate={navigate} /> },
            { path: 'app1/service/:id', element: <ServiceForm navigate={navigate} /> },
            // { path: 'app1/commission', element: <CommissionList navigate={navigate} /> },
            // { path: 'app1/commission/:id', element: <CommissionForm navigate={navigate} /> },
            { path: 'app1/transaction_histories', element: <TransactionHistoryList navigate={navigate} /> },
            // { path: 'app1/account_pool/:acc_type', element: <AccountPoolList navigate={navigate} /> },
            // { path: 'app1/account_all/:acc_type', element: <AccountAllList navigate={navigate} /> },
            { path: 'app1/bank_transactions', element: <BankTransactionList navigate={navigate} /> },
            // { path: 'app1/payoneer_transactions', element: <PayoneerTransactionList navigate={navigate} /> },
            // { path: 'app1/transfer_fund_remaining', element: <TransferFundRemaining navigate={navigate} /> },

            { path: SETTING.SERVICES, element: <ProductList navigate={navigate} /> },
            { path: 'settings/rate_ex', element: <RateExList navigate={navigate} /> },
            { path: SETTING.PLATFORMS, element: <TeamPlatformList navigate={navigate} /> },
            { path: 'settings/manual_notification', element: <ManualNotificationList navigate={navigate} /> },
            { path: 'settings/templates', element: <TemplateList navigate={navigate} /> },
            { path: 'settings/admin_users', element: <AdminUser navigate={navigate} /> },
            { path: 'settings/discounts', element: <DiscountList navigate={navigate} /> },

            { path: 'profile', element: <Profile navigate={navigate} /> },
            { path: '404', element: <NotFound navigate={navigate} /> },
            { path: '/', element: <Navigate to={getRedirectRoute()} navigate={navigate} /> },
            { path: '*', element: <Navigate to="/404" replace navigate={navigate} /> }
          ]
        },
        { path: '*', element: <Navigate to="/404" replace navigate={navigate} /> }
      ] : [
        {
          path: '/',
          element: <LogoOnlyLayout navigate={navigate} />,
          children: [
            { path: 'login', element: <Login navigate={navigate} /> },
            { path: '404', element: <NotFound navigate={navigate} /> },
            { path: '/', element: <Navigate to="/login" navigate={navigate} /> },
            { path: '*', element: <Navigate to="/404" navigate={navigate} /> }
          ]
        },
        { path: '*', element: <Navigate to="/404" replace navigate={navigate} /> }
      ]
  );
}
