import React from 'react';
import Page from '../../../components/Page';
import { Box, Container, Stack, Typography } from '@mui/material';
import ListRequestAdAccount from '../../../components/app3/RequestAdAccount/list';

export default function RequestAdsAccount() {
  return (
    <Page title="SpendAds | Request Ads Account">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box className="d-flex align-items-center">
            <Typography variant="h4">
              Request Ads Account
            </Typography>
          </Box>
        </Stack>
        <Box mt={3}>
          <ListRequestAdAccount />
        </Box>
      </Container>
    </Page>
  );
}
