import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Paper,
  Container, Stack, Typography, TablePagination, Checkbox, TextField, Grid, Tooltip,
  InputAdornment, CardContent
} from '@mui/material';
import { loadOptions } from '../utils';
import { withStyles } from '@mui/styles';
import { request } from '../_services_';
import { ModalForm, Page, CustomCircularProgress, Filter, MultiInputPicker, DatePicker } from '../components';
import { toastr } from '../_helpers_';
import clsx from 'clsx';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconRefresh } from '@tabler/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MHidden } from '../components/@material-extend';
import SelectAllIcon from '@mui/icons-material/SelectAll';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    borderRadius: 0
  },
  buttonSearch: {
    padding: 10,
    marginRight: 0,
    borderRadius: '0 7px 7px 0'
  },
  dynamic_col: {
    marginBottom: 0,
    minWidth: '100%',
    tableLayout: 'fixed',
    background: '#fff',
    '&>tbody>tr>td': {
      borderBottom: '1px solid #ebeff3',
      borderTop: 0,
    },
    '& td': {
      // height: '85px'
    }
  }, 
  table: {
    minWidth: 700,
    '&>thead:first-child>tr:first-child>th': {
      borderTop: 0,
    },
    '&>th': {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    '&>thead>tr>th': {
      padding: '5px 10px',
    },
    // '&>thead>tr': {
    //   verticalAlign: 'top'
    // },
    '&>tbody>tr>td': {
      position: 'relative',
      verticalAlign: 'middle',
      padding: '0px 10px',
      height: '50px',
      lineHeight: '1.42857',
    },
    '& p': {
      margin: 0
    }, '& a': {
      color: '#2c5cc5',
      textDecoration: 'none'
    }, '& .inline-wrapper': {
      boxShadow: '0 2px 5px 0 rgba(39,49,58,.15)',
      opacity: 1,
      background: '#fff',
      border: '1px solid #ebeff3',
      borderRadius: '20px',
      minHeight: '33px',
      padding: '7px 32px 7px 5px',
      position: 'absolute',
      right: '6px',
      top: '50%',
      transform: 'translateY(-50%)',
      // visibility: 'hidden',
    }, '& .inline-actions': {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      margin: 0,
      paddingLeft: 0,
      listStyle: 'none',
      '& li': {
        padding: '0 10px',
        display: 'inline-block',
      }
    }, table_floating: {
      width: '80%',
      float: 'left',
      '& .dynamic_col': {
        maxWidth: 'none',
      }
    }, cell_entry: {
      whiteSpace: 'nowrap',
      width: '100%',
      background: '#ECECEC'
    }
  },
  head_cell: {
    background: '#ECECEC',
    borderBottom: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    '&:last-child': {
      borderRight: 'none',
    }
  },
  item_list: {
    borderBottom: '1px solid #cecece',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[500_16]
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
})

class ResourceList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      items: [],
      totals: 0,
      loadingData: false,
      isLoadData: false,
      searchQuery: {
        page: 1,
        limit: 15
      },
      sort: this.props.defaultSort || {
        field: null,
        type: 'asc'
      },
      multipleButton: {
        buttonAnchorEl: null,
        openButton: false,
      }
    }
  }

  componentDidMount() {
    if(this.props?.setRef){
      this.props?.setRef(this);
    }
    
    const defaultFilters = this.props.defaultFilters || {}
    const defaultSort = this.props.defaultSort || {}
    const query = {
      ...this.state.searchQuery,
      ...defaultFilters,
      ...defaultSort?.field ? { sort: defaultSort.field, type_sort: defaultSort.type } : {}
    }
    if (this.props.type != 'static') {
      this.setState({ loadingData: true })
      request.get(this.props.route, query).then(res => {
        this.setState({ isLoadData: true })
        if (res.data.success) {
          this.setState({ items: res.data.data.items, totals: res.data.data.total, loadingData: false });
        } else {
          toastr.error(res.data.msg);
          this.setState({ loadingData: false })
        }
      }, err => {
        this.setState({ loadingData: false })
        toastr.error(err);
      })
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload != this.props.reload && this.props.reload) {
      const defaultFilters = this.props.defaultFilters || {}
      const query = {
        ...this.state.searchQuery,
        ...defaultFilters
      }
      this.getListItems(query)
      this.props.setReLoad && this.props.setReLoad(false)
    }
    if (JSON.stringify(prevProps.defaultFilters) != JSON.stringify(this.props.defaultFilters)) {
      const defaultFilters = this.props.defaultFilters || {}
      const query = {
        ...this.state.searchQuery,
        ...defaultFilters
      }
      this.getListItems(query)
    }
  }

  getListItems = (query) => {
    const { sort } = this.state
    const defaultFilters = this.props.defaultFilters || {}
    const queryObj = {
      ...query,
      ...defaultFilters,
      ...sort?.field ? { sort: sort.field, type_sort: sort.type } : {}
    }
    this.setState({ loadingData: true })
    request.get(this.props.route, queryObj).then(res => {
      if (res.data.success) {
        this.setState({ items: res.data.data.items, totals: res.data.data.total, loadingData: false, selectedItems: res.data.data.selectedItems || [] });
        if (queryObj['type_select'] == 'all') {
          toastr.success(`Selected ${res.data.data.total} records`)
        }
      } else {
        toastr.error(res.data.msg);
        this.setState({ loadingData: false })
      }
    }, err => {
      toastr.error(err);
      this.setState({ loadingData: false })
    })
  }

  loadOptions = (inputValue, loadingData) => {
    return loadingData(inputValue)
  };

  handleInputChange = (newValue) => {
    return newValue;
  };

  handleCheckAll = event => {
    let selectedItems;
    if (event.target.checked) {
      selectedItems = this.state.items.map(t => t[this.props.valueKey || 'id']);
    } else {
      selectedItems = [];
    }
    this.setState({ selectedItems });
  };

  handleCheck = (event, id) => {
    const selectedItems = Object.assign([], this.state.selectedItems)
    const index = selectedItems.indexOf(id)
    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(id)
      }
    } else {
      if (index > -1) {
        selectedItems.splice(index, 1)
      }
    }
    this.setState({ selectedItems })
  };

  onQueryChange = (event) => {
    const searchQuery = Object.assign({}, this.state.searchQuery)
    searchQuery[event.target.name] = event.target.value
    this.setState({ searchQuery })
  }

  handlePageChange = (event, page) => {
    const searchQuery = Object.assign({}, this.state.searchQuery)
    searchQuery.page = page + 1
    this.setState({ searchQuery }, () => {
      this.getListItems(this.state.searchQuery);
    });
  };

  handleRowsPerPageChange = (event) => {
    const searchQuery = Object.assign({}, this.state.searchQuery)
    searchQuery.page = 1
    searchQuery.limit = event.target.value
    this.setState({ searchQuery }, () => {
      this.getListItems(this.state.searchQuery);
    });
  };

  toggleDrawer = (side, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    this.setState({ [side]: open });
  };

  // Multiple Button
  handleMultiButtonClick = (event) => {
    const multipleButton = Object.assign({}, this.state.multipleButton)
    multipleButton.buttonAnchorEl = event.currentTarget
    multipleButton.openButton = true
    this.setState({ multipleButton })
  };

  handleMultiClose = () => {
    const multipleButton = Object.assign({}, this.state.multipleButton)
    multipleButton.buttonAnchorEl = null
    multipleButton.openButton = false
    this.setState({ multipleButton })
  };
  // -Multiple Button

  getOptions = (inputValue, field) => {
    return loadOptions(inputValue, field.loadOptions)
  }

  selectAllRecord = () => {
    const query = {
      ...this.state.searchQuery,
      type_select: 'all'
    }
    this.getListItems(query)
  }

  getFields = () => {
    if (typeof this.props.fields == 'function') {
      return this.props.fields(this)
    }
    return this.props.fields
  }

  getColor = (item) => {
    if (this.props.color) {
      if (typeof this.props.color == 'function') {
        return this.props.color(item)
      }
      return this.props.color
    }
    return 'black'
  }

  render() {

    const { selectedItems, items, searchQuery, sort, right } = this.state
    const { classes, fields, actions, type, data, navigate, filters } = this.props;

    return (
      <Page title={this.props.titlePage || 'Drebest'}>
        <ModalForm />
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              {this.props.title || 'List'}
            </Typography>
            {actions && actions.length > 0 &&
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {actions.filter(e => e.visible === true).map((ac, index) => (
                  <Button
                    key={index}
                    variant={ac.variant || 'contained'}
                    startIcon={ac.icon}
                    endIcon={ac.endIcon}
                    size={ac.size || 'small'}
                    onClick={() => ac.action(this)}
                  >
                    {ac.text}
                  </Button>
                ))}
              </Stack>
            }
          </Stack>
          {type == 'static' ?
            <div className='card-container' style={{}}>
              <CardContent >
                <div >
                  <ul style={{ listStyle: 'none' }}>
                    {data?.map((item, i) =>
                      <li key={i}>
                        <div className={clsx(classes.item_list, 'd-flex p-2')}
                          onClick={() => {
                            navigate(item.route)
                          }}
                        >
                          {item.icon}
                          <div className='ml-4' style={{ flex: 1 }}>
                            <div className='font-weight-bold'>
                              {item.title}
                            </div>
                            <div className=''>
                              {item.content}
                            </div>
                          </div>

                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </CardContent>

            </div> :
            <div className='card-container'>
              {/* <Scrollbar> */}
                <Grid container style={{ padding: '4px 6px', borderBottom: '1px solid #ccc' }}>
                  <Grid item xs={12} sm={12}>
                    <div className='d-flex align-items-center' style={{ gap: 5 }}>
                      <TextField
                        name="search"
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        label=""
                        placeholder='Search'
                        onChange={this.onQueryChange}
                        value={searchQuery.query}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.getListItems(searchQuery)
                          }
                        }}
                        style={{ marginTop: 4, width: "30%" }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'notchedOutline'
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant="contained"
                                className={classes.buttonSearch}
                                onClick={() => this.getListItems(this.state.searchQuery)}
                              >
                                Search
                              </Button>
                            </InputAdornment>
                          )
                        }}
                      />
                      {filters?.length > 0 &&
                        <MHidden width="lgDown">
                          {filters.map(filter => {
                            if (filter.type == 'input_picker') {
                              return (
                                <MultiInputPicker
                                  onDelete={(click) => (this.handleDeleteMultiPicker = click)}
                                  valueKey={filter.valueKey || 'id'}
                                  getOptionLabel={(item) => filter.getOptionLabel ? filter.getOptionLabel(item) : (filter.labelKey ? item[filter.labelKey] : item.name)}
                                  onChange={(items) => {
                                    const searchQuery = Object.assign({}, this.state.searchQuery)
                                    if (items.length > 0) {
                                      searchQuery[filter.key] = items.map(item => item[filter.valueKey || 'id']).join(',')
                                    } else {
                                      delete searchQuery[filter.key];
                                    }
                                    this.setState({ searchQuery }, () =>
                                      this.getListItems(this.state.searchQuery)
                                    );
                                  }}
                                  loadOptions={(inputValue) => this.getOptions(inputValue, filter)}
                                  noOptionsMessage="No results found"
                                  renderAriaLabel={(selectedItems) => filter.renderAriaLabel ? filter.renderAriaLabel(selectedItems) : `${selectedItems.length} item(s) selected`}
                                  defaultAriaLabel={filter.defaultAriaLabel}
                                />
                              )
                            } else if (filter.type == 'date_picker') {
                              return (
                                <DatePicker
                                  placeholder={filter.placeholder || ''}
                                  onChange={(value) => {
                                    const searchQuery = Object.assign({}, this.state.searchQuery)
                                    if (value.length > 0) {
                                      searchQuery[filter.key] = JSON.stringify(value)
                                    } else {
                                      delete searchQuery[filter.key];
                                    }
                                    this.setState({ searchQuery }, () =>
                                      this.getListItems(this.state.searchQuery)
                                    );
                                  }}
                                />
                              )
                            }
                          })}
                        </MHidden>
                      }
                      <Button
                        color={"primary"}
                        variant="outlined" 
                        startIcon={<FilterListIcon />}
                        onClick={() => this.setState({ right: true })}
                        aria-label="filter"
                        style={{ padding: '8px 16px' }}
                      >
                        Filter
                      </Button>
                    </div>
                  </Grid>
                </Grid>

                <Filter
                  right={right}
                  onClose={() => { this.setState({ right: false }) }}
                  fields={this.getFields()}
                  filters={{
                    filter: searchQuery?.filter,
                    filter_by: searchQuery?.filter_by,
                    join_fix: searchQuery?.join_fix,
                  }}
                  onFilter={(query) => {
                    var searchQuery = Object.assign({}, this.state.searchQuery)
                    searchQuery = { ...searchQuery, ...query }
                    this.setState({ searchQuery, right: false }, () => {
                      this.getListItems(searchQuery)
                    })
                  }}

                />
                <Paper className={classes.root} style={{ position: 'relative' }}>

                  <TableContainer style={{ maxHeight: 'calc(100vh - 260px)' }} className={classes.table}>
                    <Table stickyHeader className={clsx(classes.dynamic_col, classes.dynamic_sticky, classes.table, 'dynamic_col table-list')}>
                      <TableHead className={classes.head_white}>
                        <TableRow>
                          <TableCell
                            className={clsx(classes.table_cell, classes.cell_entry, classes.cell_entry_floating, classes.head_cell)}
                            style={this.props.invisibleCheckbox ? {width: '15px', padding: 0} : {width: '54px', textAlign: 'left'}}
                            align="center"
                          >
                            <Checkbox
                              sx={this.props.invisibleCheckbox && {display: 'none'}}
                              checked={selectedItems.length >= items.length ? true : false}
                              color="secondary"
                              style={{ padding: 5 }}
                              indeterminate={
                                selectedItems.length > 0 &&
                                selectedItems.length < items.length
                              }
                              onChange={this.handleCheckAll}
                            />
                          </TableCell>
                          {(selectedItems.length == 0 || !this.props.headerActions || this.props.headerActions?.length == 0) && this.getFields()?.map((field, i) => (
                            <TableCell
                              key={i}
                              className={clsx(classes.table_cell, classes.cell_entry, classes.cell_entry_floating, classes.head_cell)}
                              style={{ width: field.width || '12rem' }}
                              align={field.align || 'left'}
                            >
                              <Tooltip title={field.label} arrow placement='top'>
                                <a

                                  style={{
                                    cursor: field.sort ? 'pointer' : 'normal', display: 'flex',
                                    color: (sort.name == field.name) ? 'green' : 'black'
                                  }}
                                  onClick={() => {
                                    if (!field.sort) return;
                                    var sortConfig = Object.assign({}, this.state.sort)
                                    if (sortConfig.name != field.name) {
                                      sortConfig = {
                                        field: field.sortName || field.name,
                                        type: 'asc',
                                        name: field.name
                                      }
                                    } else {
                                      sortConfig = {
                                        field: field.sortName || field.name,
                                        type: sortConfig.type == 'asc' ? 'desc' : 'asc',
                                        name: field.name
                                      }
                                    }
                                    this.setState({ sort: sortConfig }, () => {
                                      this.getListItems(searchQuery)
                                    })
                                  }}>
                                  <span className='line-clamp-3'>{field.label}</span>
                                  {(sort.name == field.name) ?
                                    (sort.type == "asc" ? <ArrowDownwardIcon fontSize={"small"} /> : <ArrowUpwardIcon fontSize={"small"} />) : null
                                  }
                                </a>
                              </Tooltip>
                            </TableCell>
                          ))}
                          {this.props.headerActions?.length > 0 && selectedItems.length > 0 && 
                            <TableCell
                              className={clsx(classes.table_cell, classes.cell_entry, classes.cell_entry_floating, classes.head_cell)}
                              colSpan={this.getFields().length}
                              align='left'
                            >
                              <div className='d-flex' style={{ gap: 4 }}>
                                {selectedItems.length != this.state.totals &&
                                  <Button
                                    variant="contained"
                                    startIcon={<SelectAllIcon />}
                                    color="primary"
                                    onClick={this.selectAllRecord}
                                  >
                                    Select all {this.state.totals} records
                                  </Button>
                                }
                                {this.props.headerActions.map((ac, index) => (
                                  <Button
                                    key={index}
                                    variant={ac.variant || 'contained'}
                                    startIcon={ac.icon}
                                    endIcon={ac.endIcon}
                                    onClick={() => ac.action(this, selectedItems)}
                                    color={ac.color || 'primary'}
                                  >
                                    {ac.text}
                                  </Button>
                                ))}
                              </div>
                            </TableCell>
                          }
                          {this.props.actionRow &&
                            <TableCell
                              className={clsx(classes.table_cell, classes.cell_entry, classes.cell_entry_floating, classes.head_cell)}
                              style={this.props.invisibleCheckbox ? {width: '15px', padding: 0 } : {width: '54px', textAlign: 'left' }}
                              align="center"
                            >
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.items.map((item, index) => (
                          <TableRow
                            key={item[this.props.valueKey || index]}
                            className={`cursor-pointer ${this.props.rowActive && item[this.props.valueKey || 'id'] == this.props.rowActive ? 'row-active' : ''}`}
                            onClick={() => {
                              if (this.props.onClickRow) {
                                this.props.onClickRow(item, this)
                              }
                            }}
                          >
                            <TableCell
                              onClick={e => e.stopPropagation()}
                              padding="checkbox"
                              align="center"
                              className={clsx(classes.cell_entry)}
                              style={this.props.invisibleCheckbox ? {width: '15px', padding: 0} : {width: '3rem', textAlign: 'left'}}
                            >
                              <Checkbox
                                sx={this.props.invisibleCheckbox && {display: 'none'}}
                                checked={selectedItems.indexOf(item[this.props.valueKey || 'id']) !== -1 ? true : false}
                                color="secondary"
                                style={{ padding: 5 }}
                                onChange={event => this.handleCheck(event, item[this.props.valueKey || 'id'])}
                                value="true"
                              />
                            </TableCell>
                            {this.getFields()?.map(field => (
                              <TableCell
                                key={field.label}
                                className={clsx(classes.cell_entry)}
                                align={field.align || 'left'}
                                onClick={e => {
                                  if (field.type == 'action') {
                                    e.stopPropagation()
                                  }
                                }}
                              >
                                <div className="fnu-view" style={{ position: 'relative', overflow: "hidden" }}>
                                  <div className={classes.content_inline} style={{ display: 'flex' }}>
                                    <span className='text_2' style={{
                                      cursor: this.props.onClickRow ? 'pointer' : 'text',
                                      flex: 1,
                                      maxWidth: 'calc(100%)',
                                      wordBreak: 'break-all'

                                    }}>
                                      <div className={classes.text_ellipsis} style={{ color: this.getColor(item) }}>
                                        {field.renderValue ? field.renderValue(item) : item[field.name]}
                                      </div>
                                    </span>
                                    {field.refresh ? <a
                                      style={{ marginLeft: 10 }}
                                      onClick={(e) => {
                                        this.setState({ loadingData: true }, () => {
                                          request.put('/api/v1/reset_password', { uid: item.uid }).then(res => {
                                            if (res.data.success) {
                                              toastr.error("Reset password to 123456 success!");
                                            } else {
                                              toastr.error(res.data.msg);
                                            }
                                            this.setState({ loadingData: false })
                                          }, err => {
                                            this.setState({ loadingData: false })
                                            toastr.error(err);
                                          })
                                        })

                                        e.stopPropagation()
                                      }} className='d-flex align-items-center' rel="noreferrer">
                                      <IconRefresh size={20} stroke={2} /></a> : null}
                                  </div>
                                </div>
                              </TableCell>
                              // <TableCell key={field.name} className='cell-content' align={field.align || 'left'}>
                              //   {field.renderValue ? field.renderValue(item) : item[field.name]}
                              // </TableCell>
                            ))}
                            { this.props.actionRow &&
                              <TableCell
                                className={clsx(classes.table_cell, classes.cell_entry, classes.cell_entry_floating)}
                                style={this.props.invisibleCheckbox ? {width: '15px', padding: 0} : {width: '4rem', textAlign: 'left'}}
                                align="center"
                              >
                                <Button
                                  sx={this.props.actionRow.visibled && !this.props.actionRow.visibled(item) && {display: 'none'}}
                                  variant='contained'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (this.props.actionRow.action) {
                                      this.props.actionRow.action(item, this)
                                    }
                                  }}
                                >
                                  {this.props.actionRow.title}
                                </Button>
                              </TableCell>
                            }
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {this.state.items.length == 0 && this.state.isLoadData &&
                    <div className='d-flex align-items-center justify-content-center' style={{ width: '100%' }}>
                      <img src="/static/illustrations/no_data.webp" alt="no data" style={{ width: '30%', height: 'auto'}} />
                    </div>
                  }
                  {this.state.loadingData ?
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'rgba(255,255,255,0.8)'
                    }}>
                      <div className='p-3 d-flex align-items-center justify-content-center'>
                        <CustomCircularProgress />
                      </div>
                    </div> : null}

                </Paper>
                <TablePagination
                  className='list-pagination'
                  style={{ borderTop: '1px solid #D8D8D8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                  component="div"
                  count={this.state.totals}
                  onPageChange={this.handlePageChange}
                  onRowsPerPageChange={this.handleRowsPerPageChange}
                  page={searchQuery.page - 1}
                  rowsPerPage={searchQuery.limit >= items.length ? searchQuery.limit : items.length}
                  rowsPerPageOptions={searchQuery.limit >= items.length ? [15, 20, 50, 100] : [items.length]}
                />
              {/* </Scrollbar> */}
            </div>}
        </Container>
      </Page >
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

const connectedList = connect(mapStateToProps)(withStyles(useStyles)(ResourceList));
export { connectedList as ResourceList }