import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../../_constants_/share-style';
import { CustomCircularProgress, Scrollbar } from '../../index';
import { BG_COLOR_GREEN, productLinkStatus } from '../../../_constants_';
import { convertDateWithTimezone, getButtonColor, getLabelById } from '../../../_helpers_';
import DrawerEditProductLink from './drawerEdit';
import { getProductLink } from '../../../_actions_/product_link.actions';
import { useLocation } from 'react-router-dom';
import { clearData } from '../../../_actions_';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function ListProductLink() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const listProductLink = useSelector((state) => state?.productLink?.listProductLink);
  const [detail, setDetail] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: 50
  });
  const location = useLocation();

  const handleOpenDrawer = (productLink) => {
    setDetail(productLink);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setDetail(null);
  };

  useEffect(() => {
    if (listProductLink) dispatch(clearData('listProductLink'));
  }, [location]);

  useEffect(() => {
    if (!listProductLink) {
      dispatch(getProductLink(queryObject));
    }
  }, [listProductLink]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getProductLink(queryObject));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prevState) => ({
      ...prevState,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getProductLink(queryObject));
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          borderRadius: '8px',
          overflow: 'hidden',
          justifyContent: 'space-between'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0}
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                height: '45px',
                backgroundColor: '#fff'
              },
              minWidth: '300px',
              borderRight: 'none'
            }}
            value={queryObject.search}
            onChange={(event) => {
              setQueryObject((prev) => ({
                ...prev,
                search: event.target.value
              }));
            }}
          />
          <Button
            variant="contained"
            sx={{
              height: '45px',
              backgroundColor: BG_COLOR_GREEN,
              color: '#fff',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              '&:hover': {
                backgroundColor: '#007b55'
              }
            }}
            onClick={() => dispatch(getProductLink(queryObject))}
          >
            Search
          </Button>
        </Box>
      </Box>

      <Box>
        <Scrollbar>
          <Box className="mt-2" style={{ borderTop: '1px solid #ccc' }}>
            <Paper className={classes.root} style={{ position: 'relative' }}>
              <TableContainer className={classes.table}>
                <Table
                  stickyHeader
                  className={clsx(
                    classes.dynamic_col,
                    classes.dynamic_sticky,
                    classes.table,
                    'dynamic_col'
                  )}
                >
                  <TableHead className={classes.head_white}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '8rem' }}
                      >
                        <Tooltip title={'Request ID'} arrow placement="top">
                          <Box className="line-clamp-1">Request ID</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Timestamp'} arrow placement="top">
                          <Box className="line-clamp-1">Timestamp</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Status'} arrow placement="top">
                          <Box className="line-clamp-1">Status</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Note'} arrow placement="top">
                          <Box className="line-clamp-1">Note</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Product type'} arrow placement="top">
                          <Box className="line-clamp-1">Product type</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '15rem' }}
                      >
                        <Tooltip title={'Product URLs'} arrow placement="top">
                          <Box className="line-clamp-1">Product URLs</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Target market'} arrow placement="top">
                          <Box className="line-clamp-1">Target market</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Ads account'} arrow placement="top">
                          <Box className="line-clamp-1">Ads account</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '8rem' }}
                      >
                        <Tooltip title={'Team ID'} arrow placement="top">
                          <Box className="line-clamp-1">Team ID</Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listProductLink &&
                      listProductLink.items &&
                      listProductLink.items.map((item) => (
                        <TableRow key={item.id} onClick={() => handleOpenDrawer(item)}>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>Request {item.id}</Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>
                                    {convertDateWithTimezone(item.timestamp)}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Button
                                sx={{
                                  cursor: 'unset !important',
                                  borderRadius: '12px',
                                  fontSize: 14,
                                  color: 'white',
                                  borderColor: 'white',
                                  width: '150px',
                                  textTransform: 'none',
                                  backgroundColor: `${getButtonColor(item.status, productLinkStatus)}!important`
                                }}
                                variant="outlined"
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>{item.status}</Box>
                                  </Box>
                                </Box>
                              </Button>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>{item.note}</Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>
                                    {item?.industry?.label}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>
                                    <a
                                      href={item.url}
                                      onClick={(e) => e.stopPropagation()}
                                      target={'_blank'}
                                    >
                                      {item.url}
                                    </a>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className="text_2">
                                  <Box className={classes.text_ellipsis}>
                                    {getLabelById(+item.target_market)}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className={classes.text_ellipsis}>{item.acc_name}</Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <Box
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Box className={classes.content_inline}>
                                <Box className={classes.text_ellipsis}>Team_{item.team_id}</Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {isLoading && (
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255,255,255,0.8)'
                  }}
                >
                  <Box className="p-3 d-flex align-items-center justify-content-center">
                    <CustomCircularProgress />
                  </Box>
                </Box>
              )}
            </Paper>
            {listProductLink && (
              <TablePagination
                className="list-pagination"
                style={{
                  borderTop: '1px solid #D8D8D8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
                component="div"
                count={listProductLink?.total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={queryObject.page - 1}
                rowsPerPage={queryObject.per_page}
                rowsPerPageOptions={[15, 20, 50, 100]}
              />
            )}
          </Box>
        </Scrollbar>
      </Box>
      {detail && (
        <DrawerEditProductLink open={drawerOpen} onClose={handleCloseDrawer} productLink={detail} />
      )}
    </Box>
  );
}
