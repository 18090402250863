import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box, FormControlLabel, Checkbox
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { ModalForm, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components';
import { acc_status, fields, formfields, fudLevel } from './constants';
import { toastr, withRouter } from '../../../_helpers_';
import { request } from '../../../_services_';

const titles = {
  'gs': '6.4 GS Pool',
  'rai': '6.1 RAI Pool',
}

class AccountPoolList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newAccount = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {
        'type': this.props.match.params.acc_type
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Acc ID
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="acc_id"
              type="text"
              onChange={handleChange}
              value={submitData.values.acc_id}
              placeholder="Acc ID..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData.errors?.acc_id}
              helperText={submitData.errors?.acc_id ? submitData.errors.acc_id[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Acc name
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="acc_name"
              type="text"
              onChange={handleChange}
              value={submitData.values.acc_name}
              placeholder="Acc name..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData.errors?.acc_name}
              helperText={submitData.errors?.acc_name ? submitData.errors.acc_name[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Time zone
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="time_zone"
              type="text"
              onChange={handleChange}
              value={submitData.values.time_zone}
              placeholder="Time zone..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Partner code
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="partner_code"
              type="text"
              onChange={handleChange}
              value={submitData.values.partner_code}
              placeholder="Partner code..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Acc status manual
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  resolve(acc_status)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"status"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "status",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Acc status"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.status || null}
              styles={submitData.errors?.status ? errorStyles : customStyles}
            />
            {submitData.errors?.status && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.status[0]}</span>}
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          acc_id: { presence: { message: '^Required' } },
          acc_name: { presence: { message: '^Required' } },
        },
        onAction: (submitData) => {
          const query = submitData.values
          if (query.status) {
            query['status'] = query.status.id
          }
          return new Promise((resolve) => {
            request.post('/admin/v1/account_pool', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create account successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/account_pool/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/account_pool"
          titlePage={`SpendAds | ${titles[this.props.match.params.acc_type]}`}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newAccount(ctx),
              visible: true
            }
          ]}
          title={titles[this.props.match.params.acc_type]}
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          defaultFilters={{type: this.props.match.params.acc_type}}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/account_pool/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Account Pool Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withRouter(AccountPoolList))