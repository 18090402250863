import React, { Component } from 'react'
import { listC1C2Fields } from './fields';
import { ResourceList } from '../../../components';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_';

class RequestAll9b extends Component {
  constructor(props) {
    super(props)
    this.ctx = null
  }
  viewLinkContent = (item) => {
    if (!item.team_content_link && !item?.team_id?.content_file) {
      toastr.error("No data team content")
      return;
    }
    window.open(item?.team_content_link || item?.team_id?.content_file, '_blank')
  }

  preliminaryApprove = (item) => {
    const data = {
      preliminary_approve: 1,
      status: 'processing'
    }
    request.put(`/api/v1/request/${item.request_id}`, data).then(res => {
      if (res.data.success) {
        toastr.success('Update successful')
        this.ctx.getListItems(this.ctx.state.searchQuery)
      } else {
        toastr.error(res.data.msg)
      }
    }, err => toastr.error(err))
  }

  approveFinal = (item) => {
    const data = {
      status: 'done'
    }
    request.put(`/api/v1/request/${item.request_id}`, data).then(res => {
      if (res.data.success) {
        toastr.success('Approve successful')
        this.ctx.getListItems(this.ctx.state.searchQuery)
      } else {
        toastr.error(res.data.msg)
      }
    }, err => toastr.error(err))
  }

  viewTeamMessGroup = (item) => {
    if (!item.team_id || !item.team_id?.mess_group_link) {
      toastr.error("No data team mess group")
      return;
    }
    window.open(item.team_id?.mess_group_link, '_blank')
  }

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        setRef={(ctx) => {
          this.ctx = ctx
        }}
        route="/admin/v1/requests"
        titlePage="SpendAds | 9.2 Request C1 C2 B3"
        title="9.2 Request C1 C2 B3"
        fields={listC1C2Fields(this)}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/request_support/${item.request_id}`)}
        defaultFilters={
          { codes: JSON.stringify(['C1', 'C2', 'B3']) }
        }
      />
    )
  }

}

export default RequestAll9b;