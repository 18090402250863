import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { authentication } from './authentication';
import { locale } from './locale';
import { alert } from './alert';
import { setting } from './setting';
import { openSnackbar } from './snackbar';
import { requestTiktok } from './request-tiktok';
import { productLink } from './product_link';
import { refund } from './refund';

const rootReducer = combineReducers({
  alert,
  authentication,
  locale,
  loadingBar: loadingBarReducer,
  setting,
  openSnackbar,
  requestTiktok,
  productLink,
  refund
});

export default rootReducer;
