import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';

class RacAccList extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/accounts"
        titlePage="SpendAds | RAC ACC Disable"
        title="RAC ACC Disable"
        fields={listFields}
        valueKey="account_id"
        onClickRow={(item) => navigate(`/app3/rac_acc/${item.public_id}`)}
        defaultFilters={
          {
            type_acc: 'RAC'
          }
        }
      />
    )
  }

}

export default RacAccList;