import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_';
import { ModalForm } from '../../../components';
import { Box } from '@mui/material';

class Request9aList extends Component {

  markAsDone = (ctx, itemsUpdate, selectedNumber) => {
    let itemUpdateIds = []
    itemsUpdate.map( (item) => {
      itemUpdateIds.push(item.request_id)
    })
    const query = {status: 'done', itemIds: itemUpdateIds}
    request.put('/admin/v1/request/mark_as_done', query).then(res => {
      if (res.data.success) {
        itemsUpdate.map( (item) => {
          item.status = 'done'
        })
        ctx.setState({ itemsUpdate })
        toastr.success('Mark ' + selectedNumber.toString() + ' as done successful')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating status")
    })
  }

  handleDoneRequest = (ctx, itemsUpdate) => {
    const selectedNumber = itemsUpdate.length
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Confirm action',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              {`Mark ${selectedNumber} requests as done?`}
            </Box>
          </div>
        </div>
      ),
      action: {
        schema: {
        },
        titleAction: 'YES',
        onAction: (submitData) => {
          this.markAsDone(ctx, itemsUpdate, selectedNumber)
          return new Promise(resolve => {
            resolve(true)
          })
        }
      }
    })
  }

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="SpendAds | 9.2 A1 (DB2)"
        title="9.2 A1 (DB2)"
        fields={listFields}
        defaultFilters={
          {
            codes: JSON.stringify(['A1']),
            sort:'request_id',
            type_sort:'desc'
          }
        }
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app1/request_9a1/${item.request_id}`)}
        actionAll={[
          {
            title: 'Done',
            action: (ctx) => {
              const itemIds = ctx.state.selectedItems
              const itemsUpdate = Object.assign([], ctx.state.items).filter(
                e => 
                  !['done', 'cancel'].includes(e.status) && 
                  itemIds.includes(e.request_id)
              )
              this.handleDoneRequest(ctx, itemsUpdate)
            }
          }
        ]}
      />
    )
  }

}

export default Request9aList;