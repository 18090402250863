import React, { Component } from 'react'
import { listSinRefFields, listSinFormFields } from './fields';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class SinCreateRefList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }


  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          route="/admin/v1/sinid"
          titlePage="SpendAds | 4 SIN to create REF"
          title="4 SIN to create REF"
          fields={listSinRefFields}
          valueKey="sin_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
          ]}
        />
        <DrawerForm
          fields={listSinFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/signup_web/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="SIN Detail"
        />
      </>
    )
  }

}

export default SinCreateRefList;