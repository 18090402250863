import React, { Component } from 'react'
import { listFields } from './fields';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class SinIDList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      reload: null
    }
  }

  newSIN = () => {
    ModalForm.instance.current.openForm({
      title: "New Request",
      data: {
        create_date: moment().format('DD/MM/YYYY, HH:mm:ss')
      },
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div className=''>
            <Box fontSize={15} fontWeight='bold'>
              Team ID manual
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="team_id"
              onChange={(value) => {
                if (!submitData?.values?.team_id) {
                  ctx.setState({ reload: true }, () => {
                    ctx.setState({ reload: false })
                  })
                }
                const event = {
                  target: {
                    name: 'team_id',
                    value
                  }
                }
                handleChange(event)

              }}
              placeholder={"Acc Code"}
              menuPortalTarget={document.body}
              getOptionLabel={({ team_id }) => `Team ${team_id}`}
              getOptionValue={({ team_id }) => team_id}
              valueKey={"team_id"}
              value={submitData.values.team_id}
              styles={submitData.errors?.team_id ? errorStyles : customStyles}
            />
            {submitData.errors?.team_id && <span style={{ color: 'red' }}>{submitData.errors.team_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Other note
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="other_note"
              value={submitData.values.other_note || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Request type of Acc
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              type="text"
              // name="other_note"
              value={'Acc type B (RAC)'}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            // onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Total acc request <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="number"
              name="total_acc_request"
              value={submitData.values.total_acc_request || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.total_acc_request}

            />
            {submitData.errors?.total_acc_request && <span style={{ color: 'red' }}>{submitData.errors?.total_acc_request[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Request Acc Timezone <span style={{ color: 'red' }}>*</span>
            </Box>
            <Select
              className="MuiFormControl-marginDense"
              options={[{
                value: 'GMT-7'
              },
              {
                value: 'GMT+7'
              }
              ]}
              name="request_acc_timezone"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "request_acc_timezone",
                    value: value.value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Payment method..."}
              menuPortalTarget={document.body}
              getOptionLabel={({ value }) => value}
              getOptionValue={({ value }) => value}
              valueKey={"value"}
              value={submitData?.values?.request_acc_timezone ? { value: submitData?.values.request_acc_timezone } : null}
              styles={submitData.errors?.request_acc_timezone ? errorStyles : customStyles}
            />
            {submitData.errors?.request_acc_timezone && <span style={{ color: 'red' }}>{submitData.errors?.request_acc_timezone[0]}</span>}
          </div>


          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Product Type
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/product_types').then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="product_type_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "product_type_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Product Type"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ product_type_id }) => product_type_id}
              valueKey={"product_type_id"}
              value={submitData?.values.product_type_id || null}
              styles={submitData.errors?.product_type_id ? errorStyles : customStyles}
            />
            {submitData.errors?.product_type_id && <span style={{ color: 'red' }}>{submitData.errors?.product_type_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Domain 1 <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="domain1"
              value={submitData.values.domain1 || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.domain1}
            />
            {submitData.errors?.domain1 && <span style={{ color: 'red' }}>{submitData.errors?.domain1[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Platform of domain 1
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/platforms', { name: inputValue || '' }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="platform1"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "platform1",
                    value
                  },
                };
                handleChange(e)
              }}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ platform_id }) => platform_id}
              valueKey={"platform_id"}
              value={submitData?.values.platform1 || null}
              styles={submitData.errors?.request_acc_timezone ? errorStyles : customStyles}

            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Domain 2
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="domain2"
              value={submitData.values.domain2 || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Platform of domain 2
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/platforms', { name: inputValue || '' }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="platform2"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "platform2",
                    value
                  },
                };
                handleChange(e)
              }}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ platform_id }) => platform_id}
              valueKey={"platform_id"}
              value={submitData?.values.platform2 || null}
              styles={submitData.errors?.request_acc_timezone ? errorStyles : customStyles}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Other webste link
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="other_website_link"
              value={submitData.values.other_website_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight="bold">
              {'Ever created Acc at another Agency?'}
            </Box>
            <FormControl>
              <RadioGroup
                row
                name="exists_at_another_agency"
                value={submitData.values.exists_at_another_agency}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Can your website temporarily edit company information?
            </Box>
            <FormControl>
              <RadioGroup
                row
                name="edit_company_info"
                value={submitData.values.edit_company_info}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Can your domain change DNS temporarily?
            </Box>
            <FormControl>
              <RadioGroup
                row
                name="change_dns"
                value={submitData.values.change_dns}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 1 - Link <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="page1_link"
              value={submitData.values.page1_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.page1_link}
            />
            {submitData.errors?.page1_link && <span style={{ color: 'red' }}>{submitData.errors?.page1_link[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 1 - ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page1_id"
              value={submitData.values.page1_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.page1_id}
            />
            {submitData.errors?.page1_id && <span style={{ color: 'red' }}>{submitData.errors?.page1_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 1 - Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page1_name"
              value={submitData.values.page1_name || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 2 - Link
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="page2_link"
              value={submitData.values.page2_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 2 - ID
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page2_id"
              value={submitData.values.page2_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.page2_id}
            />
            {submitData.errors?.page2_id && <span style={{ color: 'red' }}>{submitData.errors?.page2_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 2 - Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page2_name"
              value={submitData.values.page2_name || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 3 - Link
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="page3_link"
              value={submitData.values.page3_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 3 - ID
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page3_id"
              value={submitData.values.page3_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.page3_id}
            />
            {submitData.errors?.page3_id && <span style={{ color: 'red' }}>{submitData.errors?.page3_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 3 - Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page3_name"
              value={submitData.values.page3_name || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 4 - Link
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="page4_link"
              value={submitData.values.page4_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 4 - ID
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page4_id"
              value={submitData.values.page4_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
              error={submitData.errors?.page4_id}
            />
            {submitData.errors?.page4_id && <span style={{ color: 'red' }}>{submitData.errors?.page4_id[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Page 4 - Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="page4_name"
              value={submitData.values.page4_name || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Other Page Link
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="other_page_link"
              value={submitData.values.other_page_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Share admin rights of your Pages to Drebest by?
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="share_admin_page"
              value={submitData.values.share_admin_page || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              BM Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="bm_name"
              value={submitData.values.bm_name || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              BM ID
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="bm_id"
              value={submitData.values.bm_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              BM admin link invite
            </Box>
            <TextField
              placeholder={"http://"}

              fullWidth
              margin='dense'
              type="text"
              name="bm_admin_link"
              value={submitData.values.bm_admin_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Acc in BM Screenshot
            </Box>
            <Uploader
              className={submitData.errors?.files ? 'form-upload-error' : ''}
              multiple={true}
              accept='image/*'
              uploadOnPicked
              handleChange={handleChange}
              submitData={submitData}
            />
          </div>

          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              BM ID 2 (preventive)
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="bm2_id"
              value={submitData.values.bm2_id || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Correct info
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="number"
              name="correct_info"
              value={submitData.values.correct_info || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },

              }}
              onChange={handleChange}
              error={submitData.errors?.correct_info}


            />
            {submitData.errors?.correct_info && <span style={{ color: 'red' }}>{submitData.errors?.correct_info[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Đổi TT xong
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="number"
              name="tt_done"
              value={submitData.values.tt_done || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },

              }}
              onChange={handleChange}
              error={submitData.errors?.tt_done}


            />
            {submitData.errors?.tt_done && <span style={{ color: 'red' }}>{submitData.errors?.tt_done[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Canceled
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="number"
              name="canceled"
              value={submitData.values.canceled || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },

              }}
              onChange={handleChange}
              error={submitData.errors?.canceled}

            />
            {submitData.errors?.canceled && <span style={{ color: 'red' }}>{submitData.errors?.canceled[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Note
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="note"
              value={submitData.values.note || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}
            />

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Đủ ĐK cấp Acc
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="number"
              name="grant_acc_done"
              value={submitData.values.grant_acc_done || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },


              }}
              onChange={handleChange}
              error={submitData.errors?.grant_acc_done}

            />
            {submitData.errors?.grant_acc_done && <span style={{ color: 'red' }}>{submitData.errors?.grant_acc_done[0]}</span>}

          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Pause
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="pause"
              type="number"
              value={submitData.values.pause}
              onChange={handleChange}
              placeholder="Pause"
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },

              }}
              error={submitData.errors?.pause}
            />
            {submitData.errors?.pause && <span style={{ color: 'red' }}>{submitData.errors?.pause[0]}</span>}

          </div>
          <div>

            <Box fontSize={15} fontWeight='bold'>
              Cấp Acc xong
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="grant_acc"
              value={submitData.values.grant_acc}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>

        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
        },
        onAction: (submitData, ctx) => {
          var schema = {
            total_acc_request: {
              presence: { message: '^Total acc request required' },
              numericality: {
                onlyInteger: true,
              }
            },
            request_acc_timezone: { presence: { message: '^Request Acc Timezone required' } },
            domain1: { presence: { message: '^Domain 1 required' } },
            page1_link: { presence: { message: '^Page 1 - Link required' } },
            page1_id: {
              presence: { message: '^Page 1 - ID required' },
              format: {
                pattern: /^\d+$/,
                message: '^Page 1 ID only number'
              }
            },
            page2_id: {
              format: {
                pattern: /^\d+$/,
                message: '^Page 2 ID only number'
              }
            },
            page3_id: {
              format: {
                pattern: /^\d+$/,
                message: '^Page 3 ID only number'
              }
            },
            page4_id: {
              format: {
                pattern: /^\d+$/,
                message: '^Page 4 ID only number'
              }
            },
            correct_info: {
              numericality: {
                onlyInteger: true,
              }
            },
            tt_done: {
              numericality: {
                onlyInteger: true,
              }
            },
            canceled: {
              numericality: {
                onlyInteger: true,
              }
            },
            grant_acc_done: {
              numericality: {
                onlyInteger: true,
              }
            },
            pause: {
              numericality: {
                onlyInteger: true,
              }
            },

          }
          submitData = ctx.validateForm(schema)
          if (!submitData.isValid) {
            submitData.isSubmiting = false
            ctx.setState({ submitData })
            const errors = []
            Object.keys(submitData.errors).map(key => (
              submitData.errors[key].map(error => (
                errors.push(error)
              ))
            ))
            toastr.error(errors.join('<br>'))
            resolve(false)
            return
          } else {
            var data = { 'request_type_of_acc': 'RAC' }
            var keys = Object.keys(submitData.values)
            keys = ['team_id', 'other_note', 'total_acc_request', 'request_acc_timezone', 'product_type_id',
              'pause', 'domain1', 'platform1', 'domain2', 'platform2', 'other_website_link', 'exists_at_another_agency',
              'files', 'change_dns', 'page1_link', 'page1_id', 'page1_name', 'page2_link', 'page2_id', 'page2_name',
              'page3_link', 'page3_id', 'page3_name', 'page4_link', 'page4_id', 'page4_name', 'bm_id', 'bm_name', 'bm_admin_link',
              'bm2_id', 'edit_company_info', 'other_page_link', 'share_admin_page', 'correct_info', 'tt_done', 'canceled', 'note', 'grant_acc_done',
              'grant_acc']
            keys.map(key => {
              if (submitData.values[key] != null && submitData.values[key] != undefined) {
                if (typeof submitData.values[key] === 'object') {
                  if (key === 'product_type_id') {
                    data[key] = submitData.values[key].product_type_id
                  } else if (['platform1', 'platform2'].includes(key)) {
                    data[key] = submitData.values[key].platform_id
                  } else if (['team_id'].includes(key)) {
                    data[key] = submitData.values[key].team_id
                  } else {
                    data[key] = submitData.values[key]
                  }
                } else {
                  if (['total_acc_request', 'correct_info', 'tt_done', 'canceled', 'grant_acc_done', 'grant_acc'].includes(key)) {
                    data[key] = parseInt(submitData.values[key] || 0)
                  } else {
                    data[key] = submitData.values[key]

                  }
                }
              }
            })
            return new Promise((resolve) => {
              request.post('/admin/v1/sinid', data).then(res => {
                if (res.data.success) {
                  this.setState({ reload: (this.state.reload || 0) + 1 })
                  toastr.success("Add Request support successful")
                  resolve(true)
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, error => {
                toastr.error(error?.response?.data?.msg || error.toString())
                resolve(false)
              })
            })
          }

        }
      }
    })
  }

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        reload={this.state.reload}
        route="/admin/v1/sinid"
        titlePage="SpendAds | SIN ID"
        title="SIN ID"
        fields={listFields}
        valueKey="sin_id"
        onClickRow={(item) => navigate(`/app3/sin_id/${item.sin_id}`)}
        actions={[
          {
            text: 'New',
            variant: 'contained',
            icon: <AddIcon />,
            action: (ctx) => this.newSIN(ctx),
            visible: true
          }
        ]}
      />
    )
  }

}

export default SinIDList;