import React, { Component } from 'react'
import { listRacBmFields } from './fields';
import { ResourceList } from '../../../components';

class RacBMSharing extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="SpendAds | RAC BM Sharing"
        title="RAC BM Sharing"
        fields={listRacBmFields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/rac_bm_sharing/${item.request_id}`)}
        defaultFilters={
          {
            codes: JSON.stringify(['B5']),
            acc_type: 'RAC'
          }
        }
      />
    )
  }

}

export default RacBMSharing;