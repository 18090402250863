import React, { Component } from 'react'
import { fields, formFields } from './fields';
import { ResourceList, DrawerForm } from '../../../components';
import { request } from '../../../_services_'

class TeamReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  render () {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          route="/api/v1/team_summary"
          titlePage="SpendAds | Team Reports"
          title="Team Reports"
          fields={fields}
          valueKey="team_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/team_summary/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Team Detail"
        />
      </>
    )
  }

}

export default TeamReportList;