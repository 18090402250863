import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange } from '../../../utils';
import { ModalForm, ResourceList } from '../../../components';
import { listFields } from './fields';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

class RaiAvailList extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/api/v1/newids"
        titlePage="SpendAds | 7.1.1 RAI Avail"
        title="7.1.1 RAI Avail"
        fields={listFields}
        defaultFilters={{
          type_acc: 'RAI',
          arr_status: JSON.stringify(['Active']),
          avai: 1
        }}
        valueKey="account_id"
        onClickRow={(item) => navigate(`/app1/rai_avail/${item.public_id}`)}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RaiAvailList)