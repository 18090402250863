import React from 'react';
import Page from '../../../components/Page';
import { Box, Container, Stack, Typography } from '@mui/material';
import ListProductLink from '../../../components/app3/RequestProductLink/list';

export default function RequestProductLink() {
  return (
    <Page title="SpendAds | Request product links">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box className="d-flex align-items-center">
            <Typography variant="h4">
              Request product links
            </Typography>
          </Box>
        </Stack>
        <Box mt={3}>
          <ListProductLink />
        </Box>
      </Container>
    </Page>
  );
}
