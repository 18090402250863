import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box, Button, Checkbox, FormControlLabel, InputAdornment,
} from '@mui/material';
import { discountFields, discountFormFields, productApply, typeList, typeTeam } from './fields'
import { ModalForm, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import { errorStyles, customStyles, loadOptions, makeId, handleInputChange } from '../../../utils'
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import moment from 'moment';

class DiscountList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }

  createDiscount = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Discount Name <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="title"
              type="text"
              onChange={handleChange}
              value={submitData.values.note}
              placeholder="Discount Name"
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData.errors?.title}
              helperText={submitData.errors?.title ? submitData.errors.title[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Code <span style={{ color: 'red' }}>*</span>
            </Box>
            <div className='d-flex align-items-center' style={{ gap: 10 }}>
              <div style={{ width: '70%' }}>
                <TextField 
                  fullWidth
                  margin='dense'
                  name="code"
                  type="text"
                  onChange={handleChange}
                  value={submitData.values.code}
                  placeholder="Discount Name"
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                  }}
                  error={submitData.errors?.code}
                  helperText={submitData.errors?.code ? submitData.errors.code[0] : ''}
                />
              </div>
              <div style={{ width: '30%' }}>
                <Button 
                  color='primary'
                  variant="outlined"
                  onClick={(e) => {
                    var event = {
                      target: {
                        name: 'code',
                        value: makeId(10)
                      }
                    }
                    handleChange(event)
                  }}
                >generate</Button>
              </div>
              
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Discount Type <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = typeList.filter(e => e.name.includes((inputValue || '').toUpperCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"discount_type"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "discount_type",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Discount type"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.discount_type || null}
              styles={submitData.errors?.discount_type ? errorStyles : customStyles}
            />
            {submitData.errors?.discount_type && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.discount_type[0]}</span> }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Value <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="value"
              type="number"
              onChange={handleChange}
              value={submitData.values.value}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                startAdornment: (
                  submitData.values?.discount_type?.id == 'amount' && <InputAdornment position='start'>
                    <div style={{ marginLeft: 10 }}>$</div>
                  </InputAdornment>
                
                )
              }}
              error={submitData.errors?.value}
              helperText={submitData.errors?.value ? submitData.errors.value[0] : ''}
            />
          </div>
          <div className='d-flex' style={{ marginTop: 10, gap: 5 }}>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                Start date <span style={{ color: 'red' }}>*</span>
              </Box>
              <DatePicker
                name='start_date'
                className='mt-1 font-date'
                wrapperClassName='custom-picker'
                popperClassName='custom-popper-picker'
                selected={submitData.values.start_date}
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'start_date',
                      value
                    }
                  }
                  handleChange(event)
                }}
                // timeInputLabel="Time:"
                // dateFormat="dd/MM/yyyy HH:mm:ss"
                // showTimeInput
                dateFormat='dd/MM/yyyy'
              />
              {submitData.errors?.start_date && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors?.start_date[0]}</span>}
            </div>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                End date
              </Box>
              <DatePicker
                name='end_date'
                className='mt-1 font-date'
                wrapperClassName='custom-picker'
                popperClassName='custom-popper-picker'
                selected={submitData.values.end_date}
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'end_date',
                      value
                    }
                  }
                  handleChange(event)
                }}
                // timeInputLabel="Time:"
                // dateFormat="dd/MM/yyyy HH:mm:ss"
                // showTimeInput
                dateFormat='dd/MM/yyyy'
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Type of Team <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = typeTeam.filter(e => e.name.includes((inputValue || '').toUpperCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"team_kind"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "team_kind",
                    value,
                    depends: [{
                      route: '/admin/v1/teams',
                      query: { 'team_kind': value.id },
                      key: 'id'
                    }]
                  },
                };
                handleChange(e)
              }}
              placeholder={"Type of Team"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.team_kind || null}
              styles={submitData.errors?.team_kind ? errorStyles : customStyles}
            />
            {submitData.errors?.team_kind && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors?.team_kind[0]}</span> }
          </div>
          { ['specific'].includes(submitData.values?.team_kind?.id) && 
            <div style={{ marginTop: 10 }}>
              <AsyncSelect
                 className="MuiFormControl-marginDense"
                 loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                   return new Promise(resolve => {
                     request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                       if (res.data.success) {
                         resolve(res.data.data.items.map(e => ({ id: e.team_id, name: `Team ${e.team_id}` })))
                       }
                     })
                   })
                 })}
                 defaultOptions
                 onInputChange={handleInputChange}
                 isSearchable
                 isMulti
                 name="team_ids"
                 onChange={(value) => {
                   const event = {
                     target: {
                       name: 'team_ids',
                       value
                     }
                   }
                   handleChange(event)
                 }}
                 placeholder={"Teams"}
                 menuPortalTarget={document.body}
                 getOptionLabel={({ name }) => name}
                 getOptionValue={({ id }) => id}
                 valueKey={"id"}
                 value={submitData.values.team_ids}
                 styles={submitData.errors?.team_ids ? errorStyles : customStyles}
              />
              {submitData.errors?.team_ids  && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors?.team_ids [0]}</span> }
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Service Apply <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = productApply.filter(e => e.name.includes((inputValue || '').toUpperCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"product_kind"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "product_kind",
                    value,
                    depends: [{
                      route: '/api/v1/products',
                      query: { 'product_kind': value.id },
                      key: 'id'
                    }]
                  },
                };
                handleChange(e)
              }}
              placeholder={"Service Apply"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.product_kind  || null}
              styles={submitData.errors?.product_kind  ? errorStyles : customStyles}
            />
            {submitData.errors?.product_kind  && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors?.product_kind [0]}</span> }
          </div>
          { ['specific'].includes(submitData.values?.product_kind?.id) && 
            <div style={{ marginTop: 10 }}>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/products', {search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items?.map(e => ({id: e.id, name: e.title})))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                isMulti
                name={"product_ids"}
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "product_ids",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Services"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData?.values.product_ids || null}
                styles={submitData.errors?.product_ids ? errorStyles : customStyles}
              />
              {submitData.errors?.product_ids  && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors?.product_ids [0]}</span> }
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight="bold">
              Limit number of uses
            </Box>
            <TextField
              margin="dense"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              type={'text'}
              placeholder={'Limit number of uses'}
              name={'limit_number_of_uses'}
              value={submitData.values.limit_number_of_uses}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          title: {presence: {message: '^Required'}},
          code: {presence: {message: '^Required'}},
          discount_type: {presence: {message: '^Required'}},
          value: {presence: {message: '^Required'}},
          team_kind: {presence: {message: '^Required'}},
          team_ids: function (value, attributes, attributeName) {
            if (attributes.team_kind && attributes.team_kind.id === 'specific') {
              return {
                presence: {message: '^Required'},
              };
            }
            return null;
          },
          product_kind: {presence: {message: '^Required'}},
          product_ids: function (value, attributes, attributeName) {
            if (attributes.product_kind && attributes.product_kind.id === 'specific') {
              return {
                presence: {message: '^Required'},
              };
            }
            return null;
          },
        },
        onAction: (submitData) => {
            const allKeys = Object.keys(submitData.values)
            const query = {}
            allKeys.map(key => {
              if (typeof submitData.values[key] == 'date' || submitData.values[key] instanceof Date) {
                query[key] = moment(submitData.values[key]).format('YYYY-MM-DD')
              } else if (['team_kind', 'product_kind', 'discount_type'].includes(key)) {
                query[key] = submitData.values[key]?.id
              } else if (['team_ids', 'product_ids'].includes(key)) {
                query[key] = submitData.values[key].map(e => e.id)
              } else if (['value', 'limit_number_of_uses'].includes(key)){
                query[key] = parseFloat(submitData.values[key])
              } else if (submitData.values[key] == null) {
                return
              } else {
                query[key] = submitData.values[key]
              }
            })
          return new Promise((resolve) => {
            request.post('/api/v1/discounts', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err.response?.data?.msg);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const allKeys = Object.keys(formState.changes)
    const query = {}
    allKeys.map(key => {
      if(['start_date', 'end_date'].includes(key)){
        if (formState.changes[key] instanceof Date) {
        query[key] = moment(formState.changes[key]).format('YYYY-MM-DD')
        } else {
          query[key] = ''
        }
      } else if (['team_kind', 'product_kind', 'discount_type'].includes(key)) {
        query[key] = formState.changes[key]?.id
      } else if (['team_ids', 'product_ids'].includes(key)) {
        query[key] = formState.changes[key].map(e => e.id)
      } else if (['value', 'limit_number_of_uses'].includes(key)){
        query[key] = parseFloat(formState.changes[key])
      } else if (key == 'id') {
        return
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/discount/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err?.response?.data?.msg)
    })
  }

  render () {
    const { mode, open, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/discounts"
          titlePage="SpendAds | Discounts"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.createDiscount(ctx),
              visible: true
            }
          ]}
          defaultFilters={{type: 'all'}}
          title="Discounts"
          fields={discountFields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, mode: 'view', objectId: item.id })}
        />
        <DrawerForm 
          fields={discountFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/discount/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Discount Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(DiscountList)
