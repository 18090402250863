import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box, FormControlLabel, Checkbox
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { ModalForm, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components';
import { fields, filters } from './constants';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import { formfields } from './constants'

class FUDList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  // newFUD = (ctx) => {
  //   ModalForm.instance.current.openForm({
  //     title: "New",
  //     data: {},
  //     customView: (submitData, handleChange) => (
  //       <div className='p-2'>
  //         <div>
  //           <Box fontSize={15} fontWeight='bold'>
  //             Team ID
  //           </Box>
  //           <AsyncSelect
  //             className="MuiFormControl-marginDense"
  //             cacheOptions
  //             loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
  //               return new Promise(resolve => {
  //                 request.get('/api/v1/teams', { search: inputValue }).then(res => {
  //                   if (res.data.success) {
  //                     resolve(res.data.data.items)
  //                   }
  //                 })
  //               })
  //             })}
  //             defaultOptions
  //             onInputChange={handleInputChange}
  //             isSearchable
  //             name={"team_id"}
  //             onChange={(value) => {
  //               var e = {
  //                 target: {
  //                   name: "team_id",
  //                   value
  //                 },
  //               };
  //               handleChange(e)
  //             }}
  //             placeholder={"Team"}
  //             menuPortalTarget={document.body}
  //             getOptionLabel={({ team_id }) => `Team_${team_id}`}
  //             getOptionValue={({ team_id }) => team_id}
  //             valueKey={"id"}
  //             value={submitData?.values.team_id || null}
  //             styles={submitData.errors?.team_id ? errorStyles : customStyles}
  //           />
  //           {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id[0]}</span>}
  //         </div>

  //         <div style={{ marginTop: 10 }}>
  //           <Box fontSize={15} fontWeight='bold'>
  //             Level
  //           </Box>
  //           <AsyncSelect
  //             className="MuiFormControl-marginDense"
  //             cacheOptions
  //             loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
  //               return new Promise(resolve => {
  //                 const result = fudLevel.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase()))
  //                 resolve(result)
  //               })
  //             })}
  //             defaultOptions
  //             onInputChange={handleInputChange}
  //             isSearchable
  //             name={"level"}
  //             onChange={(value) => {
  //               var e = {
  //                 target: {
  //                   name: "level",
  //                   value
  //                 },
  //               };
  //               handleChange(e)
  //             }}
  //             placeholder={"Level"}
  //             menuPortalTarget={document.body}
  //             getOptionLabel={({ name }) => name}
  //             getOptionValue={({ id }) => id}
  //             valueKey={"id"}
  //             value={submitData?.values.level || null}
  //             styles={submitData.errors?.level ? errorStyles : customStyles}
  //           />
  //           {submitData.errors?.level && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.level[0]}</span>}
  //         </div>
  //         <div style={{ marginTop: 10 }}>
  //           <Box fontSize={15} fontWeight='bold'>
  //             Note
  //           </Box>
  //           <TextField
  //             fullWidth
  //             margin='dense'
  //             name="note"
  //             value={submitData.values.note}
  //             multiline
  //             onChange={handleChange}
  //             placeholder="Note..."
  //             rows={3}
  //             variant="outlined"
  //             InputProps={{
  //               classes: {
  //                 notchedOutline: 'notchedOutline'
  //               }
  //             }}
  //           />
  //         </div>
  //       </div>
  //     ),
  //     action: {
  //       titleAction: 'Save',
  //       schema: {
  //         level: { presence: { message: '^Required' } },
  //         team_id: { presence: { message: '^Required' } },
  //       },
  //       onAction: (submitData) => {
  //         const query = {
  //           ...submitData.values,
  //           level: submitData.values.level.name,
  //           package_money: submitData.values.level.value,
  //           team_id: submitData.values.team_id.team_id,
  //           cancel: submitData.values.cancel ? 1 : 0
  //         }

  //         return new Promise((resolve) => {
  //           request.post('/admin/v1/fuds', query).then(res => {
  //             if (res.data.success) {
  //               resolve(true);
  //               toastr.success("Create FUD successful")
  //               const items = Object.assign([], ctx.state.items)
  //               items.unshift(res.data.data)
  //               ctx.setState({ items, totals: ctx.state.totals + 1 })
  //             } else {
  //               toastr.error(res.data.msg)
  //               resolve(false)
  //             }
  //           }, err => {
  //             toastr.error(err);
  //             resolve(false)
  //           })
  //         })
  //       }
  //     }
  //   })
  // }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'extend_special') {
        query[key] = Number.parseInt(formState.changes[key])
      } else if (key == 'actual_fee_sent') {
        query[key] = Number.parseFloat(formState.changes[key])
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/fud/${formState.values.fud_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/fuds"
          titlePage="SpendAds | 3.2 FUD"
          title="3.2 FUD"
          fields={fields}
          valueKey="fud_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.fud_id })}
          filters={filters}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/fud/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="FUD Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(FUDList)