// REQUEST
export const LIST_REQUEST = 'LIST_REQUEST';
export const REQUEST_TIKTOK_ACC = 'REQUEST_TIKTOK_ACC';
export const UPDATE_REQUEST_AD = 'UPDATE_REQUEST_AD';
export const DATA_REQUEST_UPDATE = 'DATA_REQUEST_UPDATE';

// PRODUCT LINK
export const LIST_PRODUCT_LINK = 'LIST_PRODUCT_LINK';
export const EDIT_PRODUCT_LINK = 'EDIT_PRODUCT_LINK';

// REFUND
export const LIST_REFUND = 'LIST_REFUND';
export const EDIT_REFUND = 'EDIT_REFUND';

// COMMON
export const GET_BC = 'GET_BC';
export const CLEAR_DATA = 'CLEAR_DATA';
export const ERROR = 'ERROR';
