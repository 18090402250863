import * as t from '../_constants_/redux';

const initialState = {
  listRefund: null,
  dataEditSuccess: null,
  editRefundErrors: null
};

export function refund(state = initialState, action) {
  switch (action.type) {
    case t.LIST_REFUND: {
      return {
        ...state,
        listRefund: action.payload
      };
    }
    case t.EDIT_REFUND: {
      return {
        ...state,
        dataEditSuccess: action.payload,
        editRefundErrors: null
      };
    }
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        errors: null
      };
    case t.ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.errors
      };
    default:
      return state;
  }
}
