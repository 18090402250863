export const APP3 = {
  REQUEST_AD_ACCOUNT: 'app3/request_ad_account',
  REQUEST_REFUND: 'app3/request_refund',
  PRODUCT_LINK: 'app3/product_links'
};

export const SETTING = {
  PLATFORMS: 'settings/platforms',
  SERVICES: 'settings/products',
};
