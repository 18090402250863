import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export default function ModalConfirm(props) {
  const { modal, onClose, handleSubmit, title, content } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="modal-form-dialog"
      open={modal}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: '16px 32px' }}>
        <Box display="flex" gap={1} justifyContent={'space-between'}>
          <Box>{title ?? 'Are you sure?'}</Box>
          <Box
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.5
              },
              mr: -2
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2} p={1}>
          {content ?? ''}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onClose}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {handleSubmit()}}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
