import React, { Component } from 'react'
import { listRef2ReviewFields, partnerCodes, formRef2ReviewFields } from './fields';
import { ModalForm, Uploader, ResourceList, DrawerForm } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class RefToReviewList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newRef = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {
        temp_review: false,
        webpage_qualified_content: false,
        pause: false,
        cancel: false
      },
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Team ID
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/teams', {search: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.team_id, name: `Team ${e.team_id}`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"team_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "team_id",
                    value,
                    depends: [{
                      route: '/admin/v1/sinid',
                      query: { 'team_id': value.id },
                      key: 'sin_id'
                    }]
                  },
                };
                handleChange(e)
              }}
              placeholder={"Team ID"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.team_id || null}
              styles={submitData.errors?.team_id ? errorStyles : customStyles}
            />
            {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id[0]}</span> }
          </div>
          { submitData.values?.team_id && 
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                SIN Number
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/admin/v1/sinid', {search: inputValue, no_ref: 1, team_id: submitData.values?.team_id.id || 0 }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items?.map(e => ({id: e.sin_id, name: `SIN ${e.sin_id}`})))
                      }
                    })
                  })
                })}
                defaultOptions={ctx.state.options['sin_id']?.map(e => ({ id: e.sin_id, name: `SIN ${e.sin_id}` })) || []}
                onInputChange={handleInputChange}
                isSearchable
                name={"sin_id"}
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "sin_id",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"SIN ID"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData?.values.sin_id || null}
                styles={submitData.errors?.sin_id ? errorStyles : customStyles}
              />
              {submitData.errors?.sin_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.sin_id[0]}</span> }
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              RAC Number
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/rac_numbers', {search: inputValue, option_query: 'has_account_free', active: 1, no_limit: 1}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.rac_id, name: `RAC ${e.rac_id} (${e.free_account})`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"rac_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "rac_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"RAC ID"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.rac_id || null}
              styles={submitData.errors?.rac_id ? errorStyles : customStyles}
            />
            {submitData.errors?.rac_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.rac_id[0]}</span> }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Partner Code
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/partner_code', {search: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.id, name: e.name})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"partner_code"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "partner_code",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Partner code"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.partner_code || null}
              styles={submitData.errors?.partner_code ? errorStyles : customStyles}
            />
            {submitData.errors?.partner_code && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.partner_code[0]}</span> }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Advise
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/users', {search: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({ id: e.uid, name: e.shortname })))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"advise_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "advise_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Advise"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.advise_id || null}
              styles={customStyles}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Note
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="note"
              type="text"
              multiline
              minRows={3}
              maxRows={10}
              onChange={handleChange}
              value={submitData.values.note}
              placeholder="Note..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              VIA ID
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="via_id"
              type="text"
              onChange={handleChange}
              value={submitData.values.via_id}
              placeholder="VIA ID..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Duyệt sơ bộ</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.temp_review}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'temp_review',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'temp_review'}
                />
              }
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Webs & Pages qualified content</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.webpage_qualified_content}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'webpage_qualified_content',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'webpage_qualified_content'}
                />
              }
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Pause</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.pause}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'pause',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'pause'}
                />
              }
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Cancel</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.cancel}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'cancel',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'cancel'}
                />
              }
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          team_id: {presence: {message: '^Required'}},
          sin_id: {presence: {message: '^Required'}},
          rac_id: {presence: {message: '^Required'}},
          partner_code: {presence: {message: '^Required'}},
        },
        onAction: (submitData) => {
          const allKeys = Object.keys(submitData.values)
          const query = {}
          allKeys.map(key => {
            if (typeof submitData.values[key] === 'object' || submitData.values[key] instanceof Object) {
              query[key] = submitData.values[key].id
            } else if (typeof submitData.values[key] === 'boolean' || submitData.values[key] instanceof Boolean) {
              query[key] = submitData.values[key] ? 1 : 0
            } else {
              query[key] = submitData.values[key]
            }
          })
          return new Promise((resolve) => {
            request.post('/admin/v1/ref_reviews', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err?.response?.data?.msg);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  qualifiedWebpage = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: 'Web & page qualified?',
      data: {},
      customView: () => (
        <div className='p-3'>
          Are you sure?
        </div>
      ),
      action: {
        onAction: () => {
          const query = {
            webpage_qualified_content: 1
          }
          return new Promise((resolve) => {
            request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
              if (res.data.success) {
                const form = Object.assign({}, formState)
                form.changes = {}
                form.values = res.data.data
                ctx.setState({ formState: form })
                toastr.success("Update successful")
                this.setState({ mode: 'view', reload: true })
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error("There was an error updating data")
              resolve(false)
            })
          })
        }
      }
    })
    
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/ref_reviews"
          titlePage="SpendAds | 6.31 REF to review"
          title="6.31 REF to review"
          fields={listRef2ReviewFields}
          defaultFilters={{list: 'to_review'}}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newRef(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={formRef2ReviewFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/ref_review/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="REF to Review Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Qualified',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.qualifiedWebpage(ctx, formState),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default RefToReviewList;