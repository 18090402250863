import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_';
import { ModalForm } from '../../../components';
import { Box } from '@mui/material';

class CommissionList extends Component {

  markAsPaid = (ctx, itemsUpdate, selectedNumber) => {
    let itemUpdateIds = []
    itemsUpdate.map( (item) => {
      itemUpdateIds.push(item.id)
    })
    const query = {'paid_com_ids': itemUpdateIds}
    request.put('/admin/v1/commissions', query).then(res => {
      if (res.data.success) {
        itemsUpdate.map( (item) => {
          item.is_payment = true
        })
        ctx.setState({ itemsUpdate })
        toastr.success('Mark ' + selectedNumber.toString() + ' as paid successful')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating status")
    })
  }

  handlePaidCommission = (ctx, itemsUpdate) => {
    const selectedNumber = itemsUpdate.length
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Confirm action',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              {`Mark ${selectedNumber} commission as paid?`}
            </Box>
          </div>
        </div>
      ),
      action: {
        schema: {
        },
        titleAction: 'YES',
        onAction: (submitData) => {
          this.markAsPaid(ctx, itemsUpdate, selectedNumber)
          return new Promise(resolve => {
            resolve(true)
          })
        }
      }
    })
  }

  selectAllResult = (ctx) => {
    const { sort } = ctx.state
    const defaultFilters = ctx.props.defaultFilters || {}
    let queryObj = {
      ...ctx.state.searchQuery,
      ...defaultFilters,
      ...sort?.field ? { sort: sort.field, type_sort: sort.type } : {}
    }
    queryObj['page'] = null
    queryObj['limit'] = null

    ctx.setState({ loadingData: true })
    request.get(ctx.props.route, queryObj).then(res => {
      if (res.data.success) {
        const selectedItems = res.data.data.items.map(e => e['id'])
        toastr.success(`Selected ${selectedItems.length} result.`)
        ctx.setState({ items: res.data.data.items, totals: res.data.data.total, loadingData: false, selectedItems });
      } else {
        toastr.error(res.data.msg);
        ctx.setState({ loadingData: false })
      }
    }, err => {
      toastr.error(err);
      ctx.setState({ loadingData: false })
    })
  }

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/commissions"
        titlePage="SpendAds | Commission"
        title="Commission list"
        fields={listFields}
        // defaultFilters={
        //   {
        //     codes: JSON.stringify(['A1']),
        //     sort:'request_id',
        //     type_sort:'desc'
        //   }
        // }
        valueKey="id"
        onClickRow={(item) => navigate(`/app1/commission/${item.id}`)}
        actionAll={[
          {
            title: 'Paid',
            action: (ctx) => {
              const itemIds = ctx.state.selectedItems
              const itemsUpdate = Object.assign([], ctx.state.items).filter(
                e => 
                  !e.is_payment && 
                  itemIds.includes(e.id)
              )
              this.handlePaidCommission(ctx, itemsUpdate)
            }
          }
        ]}
        customAction={{
          title: 'Select all result',
          action: (ctx) => {
            this.selectAllResult(ctx)
          }
        }}
      />
    )
  }
}

export default CommissionList;