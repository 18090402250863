import React, { Component } from 'react'
import { listRaiFields } from './fields';
import { ResourceList } from '../../../components';

class RaiAccDisable extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/accounts"
        titlePage="SpendAds | RAI Acc Disabled"
        title="RAI Acc Disabled"
        fields={listRaiFields}
        valueKey="account_id"
        // onClickRow={(item) => navigate(`/app3/request_support/${item.request_id}`)}
        defaultFilters={
          {
            type_acc: 'RAI',
            arr_status: JSON.stringify(['Disabled','FB Closing','FB Closing'])
          }
        }
      />
    )
  }

}

export default RaiAccDisable;