import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';

class Acc2CList extends Component {

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList 
        route="/api/v1/accounts"
        titlePage="SpendAds | 2c Acc Using"
        title="2c Acc Using"
        fields={listFields}
        valueKey="public_id"
        onClickRow={(item) => navigate(`/app3/2c_acc/${item.public_id}`)}
      />
    )
  }

}

export default Acc2CList;