import React from 'react';
import { Button, Box } from '@mui/material';
import InputField from '../../elements/input';
import SelectSearch from '../../elements/select-search';
import { AD_STATUS_UPDATE, adStatus, views } from '../../../_constants_';

export const ShowAdRequest = ({ requestDetail, changeView }) => {
  return (
    <Box display={'flex'} flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
        <Button
          variant="contained"
          onClick={() => {
            changeView(views.edit);
          }}
        >
          Edit
        </Button>
      </Box>
      {requestDetail && (
        <Box display="flex" flexDirection="column" gap={2.5}>
          <InputField
            disabled
            title="SpendAds Business Center ID"
            value={requestDetail?.ads_accounts[0]?.owner_bc_id}
            required
          />

          <SelectSearch
            disabled
            title={'Status'}
            value={requestDetail.status}
            options={AD_STATUS_UPDATE}
            required
          />

          <InputField
            disabled
            title="Request ID"
            value={`Request ${requestDetail?.id}`}
            required
          />

          <InputField
            disabled
            title="Customer's business center name"
            placeholder="Please input the business center ID"
            value={requestDetail?.bc_name}
            required
          />

          <InputField
            disabled
            title="Customer's business center ID"
            placeholder="Please input the business center ID"
            value={requestDetail?.bc_id}
            required
          />

          <InputField
            disabled
            title="Account name"
            value={requestDetail?.ads_accounts?.[0]?.name}
            required
          />

          <InputField
            disabled
            title="Ad account ID"
            value={requestDetail?.ads_accounts?.[0]?.id}
            required
          />

          <InputField disabled title="Time zone" value={requestDetail?.time_zone} required />

          <InputField
            disabled
            title="Team ID"
            value={requestDetail?.team_id ? `Team_${requestDetail?.team_id}` : ''}
            required
          />

          <InputField
            disabled
            title="Company name"
            value={requestDetail?.ad_company_name}
            required
          />

          <InputField
            disabled
            title="Company website"
            value={requestDetail?.ad_company_url}
            required
          />

          <InputField disabled title="Postal code" value={requestDetail?.post_code} required />

          <InputField
            disabled
            title="Place of registration"
            value={requestDetail?.registered_area}
            required
          />

          <InputField disabled title="Industry" value={requestDetail?.industry} required />

          <InputField
            disabled
            title="Advertiser billing address"
            value={requestDetail?.billing_address}
            required
          />
        </Box>
      )}
    </Box>
  );
};
