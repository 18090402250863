import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ResourceList, ModalForm, CustomNumberFormat, DrawerForm, SmallTinyEditor } from '../../../components';
import { fields, formFields } from './constants';
import AddIcon from '@mui/icons-material/Add';
import { TextField, Box, FormControlLabel, Checkbox } from '@mui/material';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_';
import AsyncSelect from 'react-select/async';
import { loadOptions, handleInputChange, customStyles, errorStyles, makeId } from '../../../utils';
import ExtraField from './ExtraField';
import ModalConfirm from '../../../components/setting/modal/modal-confirm';
import { modeScreen, settingOptions } from '../../../_constants_';

class ProductList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null,
      modalConfirmDelete: false,
      serviceDeleteId: null,
    }
    this.editorRef = React.createRef()
  }

  newProduct = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New product",
      size: 'md',
      data: {
        extra_field: [],
        price: '0',
        commission_rate: '10',
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='d-flex gap-2'>
            <div className='flex-1'>
              <Box fontSize={15} fontWeight='bold'>
                Service code <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                fullWidth
                margin='dense'
                name="code"
                onChange={handleChange}
                value={submitData.values.code || ''}
                variant="outlined"
                placeholder="Service code..."
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  },
                }}
                error={submitData.errors.code ? true : false}
                helperText={submitData.errors.code ? submitData.errors.code[0] : ''}
              />
            </div>
            <div className='flex-1'>
              <Box fontSize={15} fontWeight='bold'>
                Platform <span style={{ color: 'red' }}>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense mt-2"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/team_platforms', {search: inputValue, filter_by: 'status', filter: settingOptions[0].value}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                isClearable
                isMulti
                name="platforms"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'platforms',
                      value: value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Platform..."}
                menuPortalTarget={document.body}
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.platforms}
                styles={submitData.errors?.platforms ? errorStyles : customStyles}
              />
              {submitData.errors?.platforms && <span className='field-error'>{submitData.errors.platforms[0]}</span>}
            </div>
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Service name <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="title"
              value={submitData.values.title || ''}
              variant="outlined"
              placeholder="Service name..."
              onChange={handleChange}
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors.title ? true : false}
              helperText={submitData.errors.title ? submitData.errors.title[0] : ''}
            />
          </div>
          <div className='d-flex gap-2 mt-2'>
            <div className='flex-1 d-flex flex-column'>
              <div className='flex-1'>
                <Box fontSize={15} fontWeight='bold'>
                  Price <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField 
                  fullWidth
                  margin='dense'
                  name="price"
                  value={submitData.values.price}
                  onChange={handleChange}
                  placeholder="Price..."
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                    inputComponent: CustomNumberFormat
                  }}
                  error={submitData.errors.price ? true : false}
                  helperText={submitData.errors.price ? submitData.errors.price[0] : ''}
                />
              </div>
              <div className='flex-1'>
                <Box fontSize={15} fontWeight='bold'>
                  Commission Rate <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField 
                  fullWidth
                  margin='dense'
                  name="commission_rate"
                  value={submitData.values.commission_rate}
                  onChange={handleChange}
                  placeholder="Commission rate..."
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                    inputComponent: CustomNumberFormat
                  }}
                  error={submitData.errors.commission_rate ? true : false}
                  helperText={submitData.errors.commission_rate ? submitData.errors.commission_rate[0] : ''}
                />
              </div>
              <div className='d-flex justify-content-between'>
                <FormControlLabel 
                  label={"Dynamic Fee"}
                  control={
                    <Checkbox
                      checked={submitData.values.dynamic_fee === 1 ? true : false}
                      onChange={(e) => {
                        const event = {
                          target: {
                            name: 'dynamic_fee',
                            value: e.target.checked ? 1 : 0
                          }
                        }
                        handleChange(event)
                      }}
                      color="primary"
                      name={'dynamic_fee'}
                    />
                  }
                />
                <FormControlLabel 
                  label={"Auto deduct = 0"}
                  control={
                    <Checkbox
                      checked={submitData.values.auto_deduct === 1 ? true : false}
                      onChange={(e) => {
                        const event = {
                          target: {
                            name: 'auto_deduct',
                            value: e.target.checked ? 1 : 0
                          }
                        }
                        handleChange(event)
                      }}
                      color="primary"
                      name={'auto_deduct'}
                    />
                  }
                />
              </div>
            </div>
            <div className='flex-1'>
              <Box fontSize={15} fontWeight='bold'>
                Note
              </Box>
              <SmallTinyEditor
                className="mt-2"
                style={{ width: '100%' }}
                content={submitData.values.drebest_note}
                id={makeId(20)}
                ref={this.editorRef}
                onEditorChange={(content) => {
                  const event = {
                    target: {
                      name: 'drebest_note',
                      value: content
                    }
                  }
                  handleChange(event)
                }}
              />
            </div>
          </div>
          <ExtraField 
            submitData={submitData.values}
            handleChange={handleChange}
          />
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          code: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          title: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          price: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          platforms: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          commission_rate: {
            presence: { allowEmpty: false, message: '^Required' },
            numericality: {
              greaterThanOrEqualTo: 0,
              message: '^Value must be greater than or equal to %{count}'
            }
          }
        },
        onAction: (submitData) => {
          const allKeys = Object.keys(submitData.values)
          const query = {}
          allKeys.map(key => {
            if (key == 'platforms') {
              query[key] = submitData.values[key].map(e => e.id)
            } else {
              query[key] = submitData.values[key]
            }
          })
          return new Promise((resolve) => {
            request.post('/api/v1/products', query).then(res => {
              if (res.data.success) {
                ctx.getListItems(ctx.state.searchQuery)
                toastr.success("Create product successfully")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              resolve(false)
              toastr.error(err)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleDeleteService = () => {
    if (this.state.serviceDeleteId)
      request.delete(`/api/v1/product/${this.state.serviceDeleteId}`).then(res => {
        if (res.data.success) {
          toastr.success("Delete successful")
          this.setState({ mode: modeScreen.view, reload: true })
        } else {
          toastr.error(res.data.msg)
        }
      }, err => {
        toastr.error("There was an error delete data")
      }).finally(() => {
        this.setState({modalConfirmDelete: false, serviceDeleteId: null })
      })
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'platforms') {
        query[key] = formState.changes[key].map(e => e.id)
      } else if (key == 'auto_deduct') {
        query[key] = formState.changes[key] ? 1 : 0
      } else {
        query[key] = formState.changes[key]
      }
    })
    
    request.put(`/api/v1/product/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render () {
    const { open, objectId, mode } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/products"
          titlePage="SpendAds | Services"
          title="Services"
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newProduct(ctx),
              visible: true
            }
          ]}
        />
        {this.state.modalConfirmDelete && (<ModalConfirm
          modal={this.state.modalConfirmDelete}
          onClose={() => {this.setState({ modalConfirmDelete: false })}}
          handleSubmit={this.handleDeleteService}
          title={'Delete Service'}
          content={'Do you want to permanently delete this service data? This action is irreversible.'}
        />)}
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/product/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Product Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode === modeScreen.view
            },
            {
              text: 'Delete',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => {
                this.setState({serviceDeleteId: formState.values.id, modalConfirmDelete: true })
              },
              visible: mode === modeScreen.view
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode === modeScreen.edit
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode === modeScreen.edit
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ProductList)