import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList, DrawerForm, ModalForm, CustomNumberFormat } from '../../../components';
import { fields, formfields, filters } from './constants';
import { request } from '../../../_services_';
import { Box, TextField } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { toastr } from '../../../_helpers_';
import { customStyles, errorStyles, handleInputChange, loadOptions } from '../../../utils';
import AddIcon from '@mui/icons-material/Add';

class TransferFundRemaining extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  TransferFundRemaining = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: "Transfer Fund Remaining",
      data: {},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-3'>
          <div style={{ marginTop: 10, gap: 10 }} className='d-flex'>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                Transfer From Team ID <span style={{ color: 'red' }}>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/admin/v1/teams', {search: inputValue, has_fund: 1}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items?.map(e => ({id: e.team_id, name: `Team ${e.team_id}`, total_fund: e.total_fund})))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name={"team_id_from"}
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "team_id_from",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Tranfer From Team Id"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData?.values.team_id_from || null}
                styles={submitData.errors?.team_id_from ? errorStyles : customStyles}
              />
              {submitData.errors?.team_id_from && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id_from[0]}</span> }
            </div>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                To Team ID <span style={{ color: 'red' }}>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/admin/v1/teams', {search: inputValue}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items?.map(e => ({id: e.team_id, name: `Team ${e.team_id}`})))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name={"team_id_to"}
                onChange={(value) => {
                  const data = Object.assign({}, submitData)
                  if(value?.id == submitData.values?.team_id_from?.id){
                    data['isValid'] =  false 
                  } else {
                    data['isValid'] = true
                  }
                  var e = {
                    target: {
                      name: "team_id_to",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"To Team Id"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData?.values.team_id_to || null}
                styles={submitData.errors?.team_id_to ? errorStyles : customStyles}
              />
              {submitData.values?.team_id_to?.id && submitData.values?.team_id_from?.id == submitData.values?.team_id_to.id && <span style={{ color: 'red', fontSize: 12 }}>{'Cannot choose the same team to Transfer Fund Remaining'}</span>}
              {submitData.errors?.team_id_to && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id_to[0]}</span> }
            </div>
          </div>
          <div style={{ marginTop: 10, width: '50%' }}>
            <Box fontSize={15} fontWeight='bold'>
              Fund Remaining
            </Box>
            <TextField 
              margin="dense"
              disabled
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat,
                inputProps: {
                  decimalScale: 2,
                  fixedDecimalScale: true,
                },
              }}
              type={'text'}
              placeholder={'Fund Remaining'}
              name={'total_fund'}
              value={submitData.values?.team_id_from?.total_fund || 0}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight="bold">
              Amount
            </Box>
            <TextField
              margin="dense"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat,
                inputProps: {
                  decimalScale: 2,
                  fixedDecimalScale: true,
                },
              }}
              type={'text'}
              placeholder={'Amount'}
              name={'amount'}
              value={submitData.values.amount}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={submitData.errors?.amount}
              helperText={submitData.errors?.amount ? submitData.errors.amount[0] : ''}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          team_id_from: {presence: {message: '^Required'}},
          team_id_to: {presence: {message: '^Required'}},
          amount: {presence: {message: '^Required'}},
        },
        onAction: (submitData) => {
            const allKeys = Object.keys(submitData.values)
            const query = {}
            allKeys.map(key => {
              if (submitData.values[key] == null) {
                return
              } else if (submitData.values[key] instanceof Object || typeof submitData.values[key] == 'object') {
                query[key] = submitData.values[key].id
              } else if(key == 'amount') {
                query[key] = parseFloat(submitData.values[key])
              } else if (key == 'total_fund') {
                return
              } else {
                query[key] = submitData.values[key]
              }
            })
          return new Promise((resolve) => {
            request.post('/api/v1/transfer_fund', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err.response?.data?.msg);
              resolve(false)
            })
          })
        }
      }
    })
  }


  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/transfer_fund"
          // defaultFilters={{content: 'Transfer amount team to team'}}
          titlePage="SpendAds | Transfer Fund Remaining"
          title="Transfer Fund Remaining"
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id, mode: 'view' })}
          actions={[
            {
              text: 'Transfer Fund Remaining',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.TransferFundRemaining(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/transfer_fund/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Tranfer Fund Remaining detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TransferFundRemaining)