import { Icon } from '@iconify/react';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import iconSharpGroups from '@iconify/icons-ic/sharp-groups';
import iconRoundFormatListBulleted from '@iconify/icons-ic/round-format-list-bulleted';
import iconSackDollar from '@iconify/icons-fa6-solid/sack-dollar';
import iconCirclePlus from '@iconify/icons-fa6-solid/circle-plus';
import pencilIcon from '@iconify/icons-fa6-solid/pencil';
import lockIcon from '@iconify/icons-fa6-solid/lock';
import listIcon from '@iconify/icons-fa6-solid/list';
import headsetIcon from '@iconify/icons-fa6-solid/headset';
import circlePlus from '@iconify/icons-fa6-solid/circle-plus';
import networkWired from '@iconify/icons-fa6-solid/network-wired';
import icon2 from '../../assets/icon2.png'
import icon3 from '../../assets/icon3.png'
import icon4 from '../../assets/icon4.png'
import icon5 from '../../assets/icon5.png'
import icon10 from '../../assets/icon10.png'
import icon11 from '../../assets/icon11.png'
import icon12 from '../../assets/icon12.png'
import icon13 from '../../assets/icon13.png'
import icon14 from '../../assets/icon14.jpeg'
import { IconSettings, IconBell, IconTool, IconCoin } from '@tabler/icons';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import waterIcon from '@iconify/icons-fa6-solid/water';
import clipboardList from '@iconify/icons-fa6-solid/clipboard-list';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { APP3, SETTING } from '../../_constants_/router';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import LinkIcon from '@mui/icons-material/Link';

// ----------------------------------------------------------------------

const getIcon = (name, width, height) => <Icon icon={name} width={width || 22} height={height || 22} />;

const sidebarConfig = [
  {
    title: 'Team Info',
    path: '/app1/teams',
    icon: getIcon(iconSharpGroups),
    apps: ['app1']
  },
  {
    title: 'Team Reports',
    path: '/app1/team_reports',
    icon: getIcon(iconSharpGroups),
    apps: ['app1']
  },
  {
    title: 'All Users',
    path: '/app1/users',
    icon: getIcon(iconRoundFormatListBulleted),
    apps: ['app1']
  },
  {
    title: 'Total Fund',
    path: 'app1/transaction_histories',
    icon: getIcon(iconSackDollar, 20, 20),
    apps: ['app1']
  },
  // {
  //   title: '3.2 FUD',
  //   path: '/app1/fuds',
  //   icon: getIcon(iconRoundFormatListBulleted),
  //   apps: ['app1']
  // },
  {
    title: 'Services',
    path: '/app1/services',
    icon: <AddBoxIcon fontSize='small' />,
    apps: ['app1']
  },
  // {
  //   title: 'RAI Account',
  //   path: '/app1/account_pool/rai',
  //   icon: <FacebookIcon fontSize="small" />,
  //   apps: ['app1'],
  //   items: [
  //     {
  //       title: 'RAI Pool',
  //       path: '/app1/account_pool/rai',
  //       icon: getIcon(waterIcon),
  //       apps: ['app1']
  //     },
  //     {
  //       title: 'RAI All',
  //       path: '/app1/account_all/rai',
  //       icon: getIcon(clipboardList),
  //       apps: ['app1']
  //     },
  //     {
  //       title: 'RAI Topup',
  //       path: '/app1/rai_topups',
  //       icon: getIcon(iconCirclePlus),
  //       apps: ['app1']
  //     },
  //   ]
  // },
  // {
  //   title: 'GS Account',
  //   path: '/app1/account_pool/gs',
  //   icon: <GoogleIcon fontSize="small" />,
  //   apps: ['app1'],
  //   items: [
  //     {
  //       title: 'GS Pool',
  //       path: '/app1/account_pool/gs',
  //       icon: getIcon(waterIcon),
  //       apps: ['app1']
  //     },
  //     {
  //       title: 'GS All',
  //       path: '/app1/account_all/gs',
  //       icon: getIcon(clipboardList),
  //       apps: ['app1']
  //     },
  //     {
  //       title: 'GS Topup',
  //       path: '/app1/gs_topups',
  //       icon: getIcon(iconCirclePlus),
  //       apps: ['app1']
  //     },
  //   ]
  // },
  // {
  //   title: 'A1 (DB2)',
  //   path: '/app1/request_supports',
  //   icon: getIcon('bxs:badge-dollar'),
  //   apps: ['app1']
  // },
  {
    title: 'Bank Transactions',
    path: '/app1/bank_transactions',
    icon: <IconCoin size={28} stroke={2} />,
    apps: ['app1']
  },
  // {
  //   title: 'Payoneer Transactions',
  //   path: '/app1/payoneer_transactions',
  //   icon: <IconCoin size={28} stroke={2} />,
  //   apps: ['app1']
  // },
  // {
  //   title: 'Transfer Fund Remaining',
  //   path: '/app1/transfer_fund_remaining',
  //   icon: <IconCoin size={28} stroke={2} />,
  //   apps: ['app1']
  // },
  // {
  //   title: 'Commission',
  //   path: '/app1/commission',
  //   icon: <IconCoin size={28} stroke={2} />,
  //   apps: ['app1']
  // },



  
  {
    title: 'Setting services',
    path: '/settings/products',
    icon: <IconSettings size={28} stroke={2} />,
    apps: ['settings']
  },
  {
    title: 'Setting package',
    path: '/settings/rate_ex',
    icon: <IconSettings size={28} stroke={2} />,
    apps: ['settings']
  },
  {
    title: 'Setting Platform',
    path: SETTING.PLATFORMS,
    icon: <IconSettings size={28} stroke={2} />,
    apps: ['settings']
  },
  {
    title: 'Manual notification',
    path: '/settings/manual_notification',
    icon: <IconBell size={28} stroke={2} />,
    apps: ['settings']
  },
  {
    title: 'Notification template',
    path: '/settings/templates',
    icon: <IconSettings size={28} stroke={2} />,
    apps: ['settings']
  },
  {
    title: 'Admin Users',
    path: '/settings/admin_users',
    icon: <AccountCircleIcon fontSize='small' />,
    apps: ['settings']
  },
  {
    title: 'Setting Discount',
    path: '/settings/discounts',
    icon: <IconSettings size={28} stroke={2} />,
    apps: ['settings']
  },


  {
    title: 'Team active',
    path: '/app3/team_active',
    icon: getIcon(lockIcon),
    apps: ['app3']
  },
  {
    title: 'All team',
    path: '/app3/all_team',
    icon: getIcon(pencilIcon),
    apps: ['app3'],
  },
  // {
  //   title: '2c Acc Using',
  //   path: '/app3/2c_acc',
  //   icon: getIcon(listIcon),
  //   apps: ['app3'],
  // },
  {
    title: 'Request Support',
    path: 'app3/9b_request_all',
    icon: getIcon(headsetIcon),
    apps: ['app3'],
    items: [
      {
        title: 'Request All',
        path: 'app3/9b_request_all',
        icon: <img src={icon2} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      },
      {
        title: 'Request C1 C2 B3',
        path: 'app3/9b_request_c',
        icon: <img src={icon3} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      },
      {
        title: 'Request B5',
        path: 'app3/9b_request_b5',
        icon: <img src={icon4} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      },
      {
        title: 'Request Other',
        path: 'app3/9b_request_other',
        icon: <img src={icon5} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      }
    ]
  },
  {
    title: 'Request',
    path: '/app3/9b_request',
    icon: getIcon(circlePlus),
    apps: ['app3'],
  },
  {
    title: 'Creating Acc',
    path: 'app3/sin_ref',
    icon: getIcon(networkWired),
    apps: ['app3'],
    items: [
      {
        title: 'SIN to create REF',
        path: 'app3/sin_ref',
        icon: <img src={icon10} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      {
        title: 'RAC number',
        path: 'app3/rac_numbers',
        icon: <img src={icon11} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      {
        title: 'REF to review',
        path: 'app3/ref_reviews',
        icon: <img src={icon3} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      {
        title: 'REF web page qualified',
        path: 'app3/ref_web',
        icon: <img src={icon12} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      // {
      //   title: '7.1 RAC Acc ID new',
      //   path: 'app3/rac_id_new',
      //   icon: <img src={icon13} style={{ width: 22, height: 22 }} />,
      //   apps: ['app3'],

      // },
      // {
      //   title: '7.2 RAC Warehouse',
      //   path: 'app3/rac_warehouses',
      //   icon: <img src={icon14} style={{ width: 22, height: 22 }} />,
      //   apps: ['app3'],
      // },
      {
        title: 'RAC All',
        path: 'app3/rac_all',
        icon: <img src={icon14} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      },
      {
        title: 'RAC Topup',
        path: 'app3/rac_topups',
        icon: getIcon(iconCirclePlus),
        apps: ['app3'],
      }
    ]
  },
  {
    title: 'Setting',
    path: 'app3/cmn_numbers',
    icon: getIcon(networkWired),
    apps: ['app3'],
    items: [
      {
        title: 'CMN Number',
        path: 'app3/cmn_numbers',
        icon: <img src={icon10} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      {
        title: 'BR Number',
        path: 'app3/br_numbers',
        icon: <img src={icon11} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],

      },
      {
        title: 'Email Number',
        path: 'app3/email_numbers',
        icon: <img src={icon3} style={{ width: 22, height: 22 }} />,
        apps: ['app3'],
      }
    ]
  },
  {
    title: 'Request product links',
    path: APP3.PRODUCT_LINK,
    icon: <LinkIcon/>,
    apps: ['app3'],
  },
  {
    title: 'Request Tiktok Refund',
    path: APP3.REQUEST_REFUND,
    icon: <PriceChangeIcon/>,
    apps: ['app3'],
  },
  {
    title: 'Request Tiktok Ads Acc',
    path: APP3.REQUEST_AD_ACCOUNT,
    icon: <ContentPasteGoIcon/>,
    apps: ['app3'],
  },
  // {
  //   title: 'Add SIN ID',
  //   path: '/app3/sin_id',
  //   icon: getIcon(circlePlus),
  //   apps: ['app3'],
  // }, {
  //   title: 'RAC Acc Disable',
  //   path: '/app3/rac_acc',
  //   icon: getIcon(listIcon),
  //   apps: ['app3'],
  // }, {
  //   title: 'RAI Acc Disabled',
  //   path: '/app3/rai_acc_disable',
  //   icon: getIcon(listIcon),
  //   apps: ['app3'],
  // }, {
  //   title: 'RAI BM Sharing',
  //   path: '/app3/rai_bm_sharing',
  //   icon: getIcon(listIcon),
  //   apps: ['app3'],
  // }, {
  //   title: 'RAC BM Sharing',
  //   path: '/app3/rac_bm_sharing',
  //   icon: getIcon(listIcon),
  //   apps: ['app3'],
  // }
];

export default sidebarConfig;
