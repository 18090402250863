import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { ModalForm, ResourceList, DrawerForm } from '../../../components';
import { fields, formfields } from './constants';
import { request } from '../../../_services_';
import { toastr, withRouter } from '../../../_helpers_';

const platform = {
  'rai': 'facebook',
  'gs': 'google'
}

const titles = {
  'rai': '7.1 RAI All',
  'gs': '7.4 GS All'
}

class AccountAllList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newAccount = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {
        type: this.props.match.params.acc_type
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Account
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/account_pool', { search: inputValue, option: 'not_use', type: submitData.values.type }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.id, name: `${e.acc_name} (${e.time_zone})`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"account"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "account",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Account"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.account || null}
              styles={submitData.errors?.account ? errorStyles : customStyles}
            />
            {submitData.errors?.account && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.account[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Team_ID Seller
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/users', { search: inputValue, platform: platform[submitData.values.type] }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.uid, name: `Team_${e.team_id?.team_id} ${e.shortname}`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"seller"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "seller",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Team seller"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.seller || null}
              styles={submitData.errors?.seller ? errorStyles : customStyles}
            />
            {submitData.errors?.seller && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.seller[0]}</span>}
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          account: { presence: { message: '^Required' } },
          // seller: { presence: { message: '^Required' } },
        },
        onAction: (submitData) => {
          const query = {}
          const allKeys = Object.keys(submitData.values)
          allKeys.map(key => {
            if (key == 'account') {
              query['acc_id'] = submitData.values['account'].id
            } else if (key == 'seller') {
              query['user_id'] = submitData.values['seller'].id
            } else if (key == 'keep_deposit') {
              query[key] = Number.parseFloat(submitData.values[key])
            } else if (submitData.values[key] instanceof Object || typeof submitData.values[key] == 'object') {
              query[key] = submitData.values[key].id
            } else {
              query[key] = submitData.values[key]
            }
          })
          return new Promise((resolve) => {
            request.post('/admin/v1/accounts', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create account successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err.response.data.msg);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (['keep_deposit','start_spent_number','end_spent_number'].includes(key)) {
        query[key] = Number.parseFloat(formState.changes[key])
      } else if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else if (typeof formState.changes[key] == 'boolean') {
        query[key] = formState.changes[key] ? 1 : 0
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/account/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err.response.data.msg || err.response.data.message)
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/accounts"
          titlePage={`SpendAds | ${titles[this.props.match.params.acc_type]}`}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newAccount(ctx),
              visible: true
            }
          ]}
          title={titles[this.props.match.params.acc_type]}
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          defaultFilters={{ type: this.props.match.params.acc_type }}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/account/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="RAI Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withRouter(AccountAllList))