import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Button, Box, Typography, IconButton } from '@mui/material';
import InputField from '../../elements/input';
import SelectSearch from '../../elements/select-search';
import { productLinkStatus, targetMarketOption, views } from '../../../_constants_';
import { ShowProductLink } from './show';
import CloseIcon from '@mui/icons-material/Close';
import Page from '../../Page';
import { getProductLink, updateProductLinks } from '../../../_actions_/product_link.actions';
import { useDispatch, useSelector } from 'react-redux';
import { clearData } from '../../../_actions_';
import { convertDateWithTimezone } from '../../../_helpers_';

const DrawerEditProductLink = ({ open, onClose, productLink }) => {
  const [dataForm, setDataForm] = useState({});
  const [view, setView] = useState(views.show);
  const [errorForm, setErrorForm] = useState('');
  const dispatch = useDispatch();
  const dataEditSuccess = useSelector((state) => state?.productLink?.dataEditSuccess);
  const editErrors = useSelector((state) => state?.productLink?.editErrors);

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  useEffect(() => {
    if (productLink)
      setDataForm({
        status: productLink?.status,
        note: productLink?.note ?? ''
      });
  }, [productLink]);

  useEffect(() => {
    if (dataEditSuccess && productLink.team_id) {
      dispatch(
        getProductLink({
          page: 1,
          limit: 50,
          team_id: productLink.team_id
        })
      );
      handleClose();
      dispatch(clearData('dataEditSuccess'));
    }
  }, [dataEditSuccess]);

  useEffect(() => {
    if (editErrors) {
      setErrorForm(editErrors.msg.errors);
      dispatch(clearData('editErrors'));
    }
  }, [editErrors]);

  const handleChangeView = (value) => {
    setView(value);
  };

  const handleSubmit = async () => {
    dispatch(updateProductLinks(productLink.id, dataForm));
  };

  const btnSubmit = useMemo(() => {
    const valid = productLink.id && dataForm.status;
    return (
      <Button variant="contained" disabled={!valid} onClick={valid ? handleSubmit : null}>
        Save
      </Button>
    );
  }, [productLink, dataForm]);

  const handleClose = () => {
    onClose();
    setDataForm({});
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ width: 600, padding: 2 }}>
        <Box>
          <Typography variant="h4" mb={2}>
            {view === views.show ? 'Product links detail' : 'Product links edit'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
              setView(views.show);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {view === views.show ? (
          <Page title="SpendAds | Request Product Link Detail">
            <ShowProductLink productLink={productLink} changeView={handleChangeView} />
          </Page>
        ) : (
          <Page title="SpendAds | Request Product Link Edit">
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
                {btnSubmit}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleChangeView(views.show);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <InputField
                  title="Request ID"
                  value={`Request ${productLink?.id}`}
                  disabled
                />

                <InputField title="Timestamp" value={convertDateWithTimezone(productLink?.timestamp)} disabled />

                <SelectSearch
                  title={'Status'}
                  placeholder={'Status'}
                  value={dataForm.status}
                  onChange={(newValue) => {
                    handleChangeDataForm('status', newValue ? newValue.value : '');
                  }}
                  options={productLinkStatus}
                  required
                  error={errorForm.status}
                />

                <InputField
                  title="Note"
                  placeholder="Please provide a reason if applicable"
                  value={dataForm.note}
                  onChange={(event) => handleChangeDataForm('note', event.target.value)}
                  error={errorForm.note}
                />

                <InputField disabled title="Product type" value={productLink?.industry?.label} />

                <InputField disabled title="Product URLs" value={productLink?.url} />

                <InputField
                  disabled
                  title="Target market"
                  value={targetMarketOption[productLink?.target_market]?.label}
                />

                <InputField disabled title="Ads account" value={productLink?.acc_name} />

                <InputField disabled title="Team ID" value={productLink?.team_id} />
              </Box>
            </Box>
          </Page>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerEditProductLink;
