import React, { Component } from 'react'
import { listEmailFields, listEmailNumberFormFields } from './fields';
import { ModalForm, DrawerForm, ResourceList } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class EmailNumberList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newEmailNumber = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Email Number <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="email"
              type="email"
              onChange={handleChange}
              value={submitData.values.email}
              placeholder="Email..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData?.errors?.email?true:false}
              helperText={submitData.errors?.email ? submitData.errors.email[0] : ''}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          email: {
            presence: { allowEmpty: false, message: '^Email required' },
            email: {
              message: "^Your email is invalid"
            }
          }
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.post('/admin/v1/email_numbers', submitData.values).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {

    request.put(`/admin/v1/email_number/${formState.values.id}`, formState.changes).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/email_numbers"
          titlePage="SpendAds | Email Number"
          title="Email Number"
          fields={listEmailFields}
          valueKey="rac_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newEmailNumber(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={listEmailNumberFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/email_number/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Email Number Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default EmailNumberList;