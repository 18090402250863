export const BG_GREEN = '#EBF8F2';
export const COLOR_TEXT = '#212B36';
export const COLOR_WHITE = '#fff';
export const COLOR_BLACK = '#000000';
export const BG_COLOR_GREEN = '#00ab55';
export const COLOR_DISABLE = '#ececec';
export const COLOR_RED = '#ff0000';

export const PAGINATE_ARR = [15, 20, 50, 100];

// ------------------------ Status ----------------------------------
export const AD_FILTER = [
  {
    value: 0,
    label: 'All status'
  },
  { value: 'accepted', label: 'Accepted' },
  { value: 'process', label: 'Process' },
  { value: 'in_review', label: 'In review' },
  { value: 'approved', label: 'Approved' },
  { value: 'not_approved', label: 'Not approved' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'contract_has_not_taken_effect', label: 'Contract has not taken effect' },
  { value: 'disabled', label: 'Disabled' }
];

export const AD_STATUS_UPDATE = [
  { value: 'Accepted', label: 'Accepted', color: 'success' },
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'STATUS_DISABLE', label: 'Disabled', color: 'error' },
  { value: 'STATUS_PENDING_CONFIRM', label: 'In review', color: 'warning' },
  { value: 'STATUS_CONFIRM_FAIL', label: 'Not approved', color: 'error' },
  { value: 'STATUS_ENABLE', label: 'Approved', color: 'success' },
  { value: 'STATUS_LIMIT', label: 'Suspended', color: 'error' },
  { value: 'STATUS_CONTRACT_PENDING', label: 'Contract has not taken effect', color: 'error' }
];

export const adStatus = [
  {
    value: 0,
    label: 'All status'
  },
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'Accepted', label: 'Accepted', color: 'success' },
  { value: 'In review', label: 'In review', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Not approved', label: 'Not approved', color: 'error' },
  { value: 'Suspended', label: 'Suspended', color: 'error' },
  {
    value: 'Contract has not taken effect',
    label: 'Contract has not taken effect',
    color: 'error'
  },
  { value: 'Disabled', label: 'Disabled', color: 'error' }
];

export const productLinkStatus = [
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Cancel', label: 'Cancel', color: 'error' }
];

export const refundStatus = [
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Cancel', label: 'Cancel', color: 'error' }
];

//----------------------- Select Option ---------------------------------
export const placeRegistration = [{ value: 'VN', label: 'Việt Nam' }];

export const settingOptions = [
  { value: 'Active', label: 'Active', color: 'success' },
  { value: 'Inactive', label: 'Inactive', color: 'error' }
];

export const targetMarketOption = [
  { value: 1, label: 'Việt Nam' },
  { value: 2, label: 'SEA' },
  { value: 3, label: 'US-UK' }
];

//----------------------- View Option ---------------------------------
export const views = {
  show: 1,
  edit: 2
};

export const modeScreen = {
  view: 'view',
  edit: 'edit'
};

//----------------------- Other ---------------------------------
