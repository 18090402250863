import React, { Component } from 'react'
import { listRefWebFields, formRefWebFields, partnerCodes } from './fields';
import { ModalForm, Uploader, ResourceList, DrawerForm } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class RefWebPageList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/ref_reviews"
          titlePage="SpendAds | 6.37 REF web page qualified"
          title="6.37 REF web page qualified"
          fields={listRefWebFields}
          defaultFilters={{list: 'to_submit'}}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
        />
        <DrawerForm 
          fields={formRefWebFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/ref_review/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Ref Review"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default RefWebPageList;