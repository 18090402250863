import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Button, Box, Typography, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../elements/input';
import SelectSearch from '../../elements/select-search';
import {
  getBC,
  getDataRequestUpdate,
  getListRequest,
  updateRequestAd
} from '../../../_actions_/request-tiktok.actions';
import { AD_STATUS_UPDATE, placeRegistration, views } from '../../../_constants_';
import { ShowAdRequest } from './show';
import CloseIcon from '@mui/icons-material/Close';
import { formatDataOptionBC } from '../../../_helpers_';
import { clearData } from '../../../_actions_';
import { CustomCircularProgress } from '../../index';


const DrawerEditRequest = ({ open, onClose, requestDetail }) => {
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = useState({});
  const [view, setView] = useState(views.show);
  const [errorForm, setErrorForm] = useState('');
  const dataRequestUpdate = useSelector((state) => state?.requestTiktok?.dataRequestUpdate);
  const dataBC = useSelector((state) => state?.requestTiktok?.dataBC);
  const dataUpdateRequest = useSelector((state) => state?.requestTiktok?.dataUpdateRequest);
  const updateRequestLoading = useSelector((state) => state?.requestTiktok?.updateRequestLoading);

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  useEffect(() => {
    if (requestDetail)
      setDataForm({
        status: requestDetail?.status,
        spendads_bc_id: requestDetail?.ads_accounts[0]?.owner_bc_id
      });
  }, [requestDetail]);

  useEffect(() => {
    if (!dataRequestUpdate) {
      dispatch(getDataRequestUpdate());
    }
    if (!dataBC) {
      dispatch(getBC());
    }
  }, []);

  const handleChangeView = (value) => {
    setView(value);
  };

  useEffect(() => {
    if (dataUpdateRequest) {
      onClose();
      setView(views.show);
      dispatch(
        getListRequest({
          page: 1,
          per_page: 50
        })
      );
      dispatch(clearData('dataUpdateRequest'));
    }
  }, [dataUpdateRequest]);

  const handleSubmit = async () => {
    const valid = dataForm.status && requestDetail?.id && dataForm.spendads_bc_id;
    if (valid) {
      const dataSubmit = {
        spendads_bc_id: dataForm.spendads_bc_id,
        status: dataForm.status
      };
      dispatch(updateRequestAd(requestDetail?.id, dataSubmit));
    }
  };

  const btnSave = useMemo(() => {
    const valid = dataForm.status && requestDetail?.id && dataForm.spendads_bc_id;
    return (
      <Button variant="contained" onClick={handleSubmit} disabled={!valid || updateRequestLoading} >
        Save {' '} {updateRequestLoading ? (<CustomCircularProgress size="1rem" />): ''}
      </Button>
    );
  }, [dataForm, requestDetail, updateRequestLoading]);

  return (
    <Drawer anchor="right" open={open}>
      <Box sx={{ width: 600, padding: 2 }}>
        <Box>
          <Typography variant="h4" mb={2}>
            {view === views.show ? 'Ads Account Detail' : 'Ads Account Edit'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose();
              setView(views.show);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {view === views.show ? (
          <ShowAdRequest requestDetail={requestDetail} changeView={handleChangeView} />
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
              {btnSave}
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  handleChangeView(views.show);
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box display="flex" flexDirection="column" gap={2.5}>
              <SelectSearch
                title={'SpendAds Business Center ID'}
                value={dataForm.spendads_bc_id}
                onChange={(newValue) => {
                  handleChangeDataForm('spendads_bc_id', newValue ? newValue.value : '');
                }}
                options={formatDataOptionBC(dataBC, '<br />')}
                required
                error={errorForm.spendads_bc_id}
              />

              <SelectSearch
                title={'Status'}
                placeholder={'Status'}
                value={dataForm.status}
                onChange={(newValue) => {
                  handleChangeDataForm('status', newValue ? newValue.value : '');
                }}
                options={AD_STATUS_UPDATE}
                required
                error={errorForm.status}
              />

              <InputField
                disabled
                title="Request ID"
                value={`Request ${requestDetail?.id}`}
                required
              />

              <InputField
                disabled
                title="Customer's business center name"
                placeholder="Please input the business center name"
                value={requestDetail?.bc_name}
                required
              />

              <InputField
                disabled
                title="Customer's business center ID"
                placeholder="Please input the business center ID"
                value={requestDetail?.bc_id}
                required
              />

              <InputField
                disabled
                title="Account name"
                value={dataForm?.name}
                placeholder="Please input Account name"
                required
              />

              <InputField
                disabled
                title="Ad account ID"
                value={dataForm?.ad_account_id}
                placeholder="Please input Ad account ID"
                required
              />

              <SelectSearch
                disabled
                title={'Time zone'}
                value={requestDetail?.time_zone}
                options={dataRequestUpdate?.time_zone ?? []}
                required
              />

              <InputField
                disabled
                type="number"
                title="Team ID"
                value={requestDetail?.team_id}
                required
              />

              <InputField
                disabled
                title="Company name"
                value={requestDetail?.ad_company_name}
                required
              />

              <InputField
                disabled
                title="Company website"
                value={requestDetail?.ad_company_url}
                required
              />

              <InputField
                disabled
                title="Postal code"
                value={requestDetail?.post_code}
                required
              />

              <SelectSearch
                disabled
                title={'Place of registration'}
                value={requestDetail?.registered_area}
                options={placeRegistration}
                required
              />

              <InputField disabled title="Industry" value={requestDetail?.industry} required />

              <InputField
                disabled
                onChange={(event) => handleChangeDataForm('billing_address', event.target.value)}
                title="Advertiser billing address"
                value={requestDetail?.billing_address}
                required
              />
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerEditRequest;
