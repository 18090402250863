// color
import { BG_GREEN } from './common';

export const STYLE_TABLE = {
  minWidth: 700,
  '&>thead:first-child>tr:first-child>th': {
    borderTop: 0
  },
  '&>th': {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  },
  '&>thead>tr>th': {
    padding: '0px 10px'
  },
  '&>tbody>tr>td': {
    position: 'relative',
    verticalAlign: 'middle',
    padding: '0px 10px',
    height: '65px',
    lineHeight: '1.42857'
  },
  '& p': {
    margin: 0
  },
  '& a': {
    color: '#2c5cc5',
    textDecoration: 'none'
  },
  '& .inline-wrapper': {
    boxShadow: '0 2px 5px 0 rgba(39,49,58,.15)',
    opacity: 1,
    background: '#fff',
    border: '1px solid #ebeff3',
    borderRadius: '20px',
    minHeight: '33px',
    padding: '7px 32px 7px 5px',
    position: 'absolute',
    right: '6px',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  '& .inline-actions': {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    margin: 0,
    paddingLeft: 0,
    listStyle: 'none',
    '& li': {
      padding: '0 10px',
      display: 'inline-block'
    }
  },
  table_floating: {
    width: '80%',
    float: 'left',
    '& .dynamic_col': {
      maxWidth: 'none'
    }
  },
  cell_entry: {
    whiteSpace: 'nowrap',
    width: '100%'
  },
  '&>tbody>tr': {
    '&:hover': { backgroundColor: BG_GREEN }
  }
};

export const STYLE_DYNAMIC_COL = {
  marginBottom: 0,
  minWidth: '100%',
  tableLayout: 'fixed',
  background: '#fff',
  '&>tbody>tr>td': {
    borderBottom: '1px solid #ebeff3',
    borderTop: 0
  }
};

export const STYLE_HEAD_CELL = {
  background: '#ECECEC',
  borderBottom: '1px solid #ccc',
  borderRight: '1px solid #ccc',
  '&:last-child': {
    borderRight: 'none'
  }
};

export const STYLE_TABLE_PAYMENT = {
  root: {
    width: '100%',
    overflowX: 'auto',
    borderRadius: 0
  },
  head_cell: { ...STYLE_HEAD_CELL },
  dynamic_col: { ...STYLE_DYNAMIC_COL },
  table: {
    minWidth: 700,
    ...STYLE_TABLE,
    '&>thead>tr>th': {
      padding: '8px 10px'
    }
  }
};
